import React from 'react';
import Navbar from '../../components/Navigation';
import Layout from '../../components/Layout';
import { Row, Col, Container } from 'reactstrap';
import { coursesMetaTags } from '../../helpers/constants';
import { StyledHero, StyledHeroContent, StyledContent } from './styled';
import Button from '../../components/UI/Button';

const NotFound = () => (
  <Layout metaTags={coursesMetaTags}>
    <StyledHero>
      <Navbar background />
      <StyledHeroContent>
        <Container>
          <Row>
            <Col xs={12}>
              <StyledContent>
                <h2>404</h2>
                <p>Sorry, we can't find that page!</p>
                <p> It might be an old link or maybe it moved.</p>
                <Button href={`/course-search`}>Go Home</Button>
              </StyledContent>
            </Col>
          </Row>
        </Container>
      </StyledHeroContent>
    </StyledHero>
  </Layout>
);

export default NotFound;
