import styled from 'styled-components';
import coderImage from '../../public/static/image/coder.jpg';

export const StyledLogin = styled.section`
  display: flex;
`;

export const StyledLoginLeft = styled.div`
  text-align: center;
  background: white;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const StyledLeftContent = styled.div`
  width: 100%;
  padding: 1rem;
  margin-top: 25%;
  @media (min-width: 480px) {
    padding: 0 1rem;
    margin-top: 0;
  }

  @media (min-width: 992px) {
    padding: 0 15rem;
  }
`;

export const StyledLoginRight = styled.div`
  min-height: 100vh;
  height: 100%;
  text-align: center;
  overflow: hidden;
  background-image: url(${coderImage});
  background-position-y: 70%;
  background-size: cover;
  align-items: center;
  justify-content: center;
  display: none;
  width: 70%;

  @media (min-width: 480px) {
    display: block;
  }
`;

export const StyledLoginTitle = styled.h1`
  padding-bottom: 1rem;
  font-size: 1.5rem;
  width: 100%;
  line-height: 37px;
  color: #202a34;
  font-weight: 600;
  text-align: left;
`;

export const StyledLogoWrapper = styled.div`
  display: flex;
`;

export const StyledLogo = styled.img`
  cursor: pointer;
  min-width: 150px;
  padding-bottom: 2.5rem;
`;

export const StyledLoginContent = styled.div`
  padding-top: 6rem;
  @media (min-width: 480px) {
    padding-top: 0rem;
  }
`;
