import styled from 'styled-components';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export const StyledModal = styled(Modal)``;

export const StyledModalHeader = styled(ModalHeader)`
  padding: 1rem 1rem 1.5rem 1rem;
`;

export const StyledModalBody = styled(ModalBody)`
  padding: 0 1rem 1rem 1rem;
`;

export const StyledModalFooter = styled(ModalFooter)`
  border: none;
  padding: 0;
`;
