import React from 'react';
import {
  StyledCourseReviewChart,
  StyledProgressBar,
  StyledProgressWrapper,
  StyledProgressLabel,
  StyledProgressTotal,
  StyledBar,
  StyledFiller,
} from './styled';

const Filler = ({ percentage }) => {
  return <StyledFiller style={{ width: `${percentage}%` }} />;
};

const ProgressBar = ({ percentage }) => (
  <StyledBar>
    <Filler percentage={percentage} />
  </StyledBar>
);

const CourseReviewChart = ({ reviewPercentages }) => {
  const renderProgressBars = reviewPercentages =>
    reviewPercentages.map(bar => (
      <StyledProgressBar key={bar.name}>
        <StyledProgressLabel>{bar.name}</StyledProgressLabel>
        <StyledProgressWrapper>
          <ProgressBar percentage={bar.value} />
        </StyledProgressWrapper>
        <StyledProgressTotal>{bar.total}</StyledProgressTotal>
      </StyledProgressBar>
    ));

  return (
    <StyledCourseReviewChart>{reviewPercentages && renderProgressBars(reviewPercentages)}</StyledCourseReviewChart>
  );
};

export default CourseReviewChart;
