import styled, { css } from 'styled-components';
import { Alert } from 'reactstrap';
import {
  spacer,
  multiply,
  color as styleColor,
  themeColor,
  alertBackgroundColor,
  alertBorderColor,
} from '../../../helpers/style';
import Button from '../Button';

export const StyledAlert = styled(Alert)`
  display: flex;
  justify-content: start;
  align-items: center;
  font-size: 1rem;
  font-weight: 500;

  ${({ color }) => css`
    color: ${themeColor(color)};
    border-color: ${alertBorderColor(color)};
    background-color: ${alertBackgroundColor(color)};
  `}
`;

export const StyledAlertContent = styled.div`
  & > *:last-child {
    margin-bottom: 0;
  }
`;

export const StyledIcon = styled.svg`
  margin-right: ${spacer};
`;

export const StyledButton = styled(Button)`
  position: absolute;
  top: ${spacer};
  right: ${spacer};
  padding: ${multiply(spacer, 0.5)};
  line-height: 0;
  background-color: transparent;

  ${({ color }) => css`
    color: ${themeColor(color)};
    border-color: ${alertBorderColor(color)};

    &:hover {
      border-color: ${themeColor(color)};
    }
  `}

  svg {
    margin-right: 0;
  }

  &:hover {
    background-color: ${styleColor('white')};
  }
`;
