import { takeLatest, call, put, all } from 'redux-saga/effects';
import { firestore } from '../../firebase/firebase.utils';
import { fetchCoursesSuccess, fetchCoursesFailure } from './course.actions';
import CourseActionTypes from './course.types';

export function* fetchCoursesAsync({ term }) {
  try {
    const collectionRef = firestore.collection('courses');

    if (term) {
      const termLowerCase = term.toLowerCase();
      const snapshot = yield collectionRef.where('tags', 'array-contains', `${termLowerCase}`).get();
      const coursesData = snapshot.docs.map(doc => {
        const courses = doc.data();
        const id = doc.id;
        return { ...courses, docRef: id };
      });

      yield put(fetchCoursesSuccess(coursesData));
    } else {
      const snapshot = yield collectionRef.get();
      const coursesData = snapshot.docs.map(doc => {
        const courses = doc.data();
        const id = doc.id;
        return { ...courses, docRef: id };
      });

      yield put(fetchCoursesSuccess(coursesData));
    }
  } catch (error) {
    yield put(fetchCoursesFailure(error.message));
  }
}

export function* fetchCoursesStart() {
  yield takeLatest(CourseActionTypes.FETCH_COURSES_START, fetchCoursesAsync);
}

export function* courseSagas() {
  yield all([call(fetchCoursesStart)]);
}
