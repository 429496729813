import styled from 'styled-components';
import FormWrapper from '../../styles/Form';
import { color } from '../../../helpers/style';
import { Field } from 'formik';

export const StyledReviewForm = styled(FormWrapper)`
  input,
  textarea {
    border: 1.25px solid;
    margin-top: 10px;

    &::placeholder {
      color: ${color('mercury')};
    }
  }

  label {
    margin-bottom: 1rem;
    color: ${color('alabaster')};
    font-weight: 400;
  }
`;

export const StyledTextarea = styled(Field)`
  min-height: 250px;
`;

export const StyledButtonBlock = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    width: auto;
    background: ${color('blueSea')};
    font-size: 0.85rem;
    font-weight: 400;
    letter-spacing: 0;
    &:hover {
      background: ${color('matisse')};
    }
  }
`;
