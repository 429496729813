import React, { Fragment } from 'react';
import {
  StyledCourseCard,
  StyledCourseTitle,
  StyledCourseMeta,
  StyledCourseContent,
  StyledCourseMetaItem,
  StyledCourseImage,
  StyledCourseLikeButton,
  StyledButtonWrapper,
  StyledCourseRatingNumber,
  StyledCourseRating,
} from './styled';
import { Link } from 'react-router-dom';
import Button from '../../UI/Button';
import Stars from '../../Stars';
import { calculateReviewData } from '../../../pages/Courses/helper';

const CourseCard = ({
  currentCourse: { id, slug, image, title, description, reviewScores, purchaseUrl },
  toggleCourseWishList,
  button,
  full,
  likedCourses,
  hover,
  border,
}) => {
  const renderCourseCard = likedCourses => {
    const reviewCount = reviewScores ? Object.values(reviewScores).length : 0;
    return (
      <StyledCourseCard hover={hover} border={border}>
        <StyledCourseImage src={image} alt={title} bordershadow />
        {likedCourses && (
          <Fragment>
            <StyledCourseLikeButton
              onClick={e => toggleCourseWishList(e, id)}
              courseLiked={likedCourses.includes(id)}
            />
          </Fragment>
        )}
        <StyledCourseContent>
          <StyledCourseTitle>{title}</StyledCourseTitle>
          <StyledCourseRating>
            <Stars count={reviewScores && calculateReviewData(Object.values(reviewScores))} />
            <div>
              <StyledCourseRatingNumber>
                {reviewCount ? `${reviewCount} Reviews` : 'No Reviews'}
              </StyledCourseRatingNumber>
            </div>
          </StyledCourseRating>

          <StyledCourseMeta>
            <StyledCourseMetaItem full={full}>{description}</StyledCourseMetaItem>
          </StyledCourseMeta>
          {button && (
            <StyledButtonWrapper>
              <Button href={`${purchaseUrl}`}>Purchase Course</Button>
            </StyledButtonWrapper>
          )}
        </StyledCourseContent>
      </StyledCourseCard>
    );
  };

  return (
    <Fragment>
      {hover ? (
        <Link to={`/course/${slug}`} style={{ textDecoration: 'none', background: 'red' }}>
          {renderCourseCard(likedCourses)}
        </Link>
      ) : (
        renderCourseCard(likedCourses)
      )}
    </Fragment>
  );
};

export default CourseCard;
