import { grays } from './colors';

export const borderWidth = '1px';
export const borderColor = grays[400];
export const borderStyle = `${borderWidth} solid ${borderColor}`;
export const borderRadii = {
  base: '3px',
  sm: '2px',
  lg: '5px',
};
export const boxShadows = {
  base: '0 10px 20px rgba(12, 33, 65, 0.13)',
  sm: '0 10px 20px rgba(12, 33, 65, 0.13)',
  lg: '0 10px 20px rgba(197, 202, 209, 0.56), 0 25px 20px rgba(255, 210, 0, 0.07)',
};
