import { multiply } from '../../helpers/style/units';
import { grays, colors } from './colors';

export const bodyBackground = colors.white;
export const bodyColor = grays[700];

export const fontSizeBase = '1rem';

export const fontSizes = {
  base: fontSizeBase,
  sm: multiply(fontSizeBase, 0.75),
  md: multiply(fontSizeBase, 0.875),
  lg: multiply(fontSizeBase, 1.25),
};

export const fontWeights = {
  base: 400,
  headings: 700,
  light: 300,
  normal: 400,
  medium: 500,
  bold: 700,
  black: 900,
};

export const lineHeights = {
  base: 1.5,
  headings: 1.25,
  headingsLg: 1.625,
  sm: 1.25,
  lg: 2,
};
