import styled from 'styled-components';

export const LightBackground = styled.section`
  padding: 2rem 0;

  @media (min-width: 780px) {
    padding: 6rem 0;
  }
  @media (min-width: 480px) {
    padding: 4rem 0;
  }
`;

export const DarkBackground = styled.section`
  background: #eff3f5;
  padding: 6rem 0;
`;
