export const fontFamilySansSerif = `Poppins, sans-serif;, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
  Ubuntu, Cantarell, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji'`;
export const fontFamilySerif = "bitter, Georgia, Times, 'Times New Roman', serif";
export const fontFamilyMonospace = `SFMono-Regular, Menlo, Monaco, Consolas, 'Roboto Mono', 'Oxygen Mono',
  'Ubuntu Mono', 'DejaVu Sans Mono', 'Liberation Mono', 'Courier New', monospace`;

export const fontFamilies = {
  base: fontFamilySansSerif,
  headings: fontFamilySansSerif,
  serif: fontFamilySerif,
  sanSerif: fontFamilySansSerif,
  monospace: fontFamilyMonospace,
};
