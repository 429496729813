import React from 'react';
import {
  StyledSalesIntro,
  SalesIntroBlock,
  SalesIntroContent,
  StyledSalesIntroParagraph,
  StyledSalesHeading,
  StyledSalesText,
} from './styled';
import Icon from '../../UI/Icon';
import SocialProof from '../../SocialProof';

const SalesIntro = () => {
  return (
    <StyledSalesIntro>
      <div className="container">
        <div className="ext-center mb-5">
          <SocialProof />
        </div>

        <StyledSalesHeading>
          <StyledSalesText>
            <div className="row justify-content-center">
              <div className="col-lg-7 col-md-8">
                <div className="ext-center">
                  <h2>Learn the skills you need to do the job you love.</h2>
                  <StyledSalesIntroParagraph>
                    We take the pain out of searching for courses with FREE reviews from different platforms and
                    authors all in one place.
                  </StyledSalesIntroParagraph>
                </div>
              </div>
            </div>
          </StyledSalesText>
          <div className="row no-gutters">
            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
              <SalesIntroBlock>
                <Icon name="ti-pencil" color="#1c85e8" backgroundColor="#1b73c54a" size="30px" />
                <SalesIntroContent>
                  <h5 className="mb-4">Trusted Reviews</h5>
                  <p>Reviews by real developers, make an informed desicision before you buy.</p>
                </SalesIntroContent>
              </SalesIntroBlock>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
              <SalesIntroBlock>
                <Icon name="ti-thumb-up" color="#1c85e8" backgroundColor="#1b73c54a" size="30px" />
                <SalesIntroContent>
                  <h5 className="mb-4">Save Money</h5>
                  <p>Don't waste money on poor quality courses, purchase the best courses online.</p>
                </SalesIntroContent>
              </SalesIntroBlock>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
              <SalesIntroBlock>
                <Icon name="ti-face-smile" color="#1c85e8" backgroundColor="#1b73c54a" size="30px" />
                <SalesIntroContent>
                  <h5 className="mb-4">Join A Community</h5>
                  <p>Share and review your own expereinces via our easy to use interface.</p>
                </SalesIntroContent>
              </SalesIntroBlock>
            </div>
          </div>
        </StyledSalesHeading>
      </div>
    </StyledSalesIntro>
  );
};

export default SalesIntro;
