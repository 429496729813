import styled from 'styled-components';
import { DropdownToggle, DropdownItem } from 'reactstrap';
import { DropdownMenu } from 'reactstrap';

export const StyledDropdownToggle = styled(DropdownToggle)`
  padding: 0;
  background: transparent;
  border: 0;

  &:hover,
  &:active,
  &:focus {
    background: transparent;
    outline: 0;
    box-shadow: none;
  }

  .dropdown {
    button {
      background: transparent;
    }
  }
`;

export const StyledDropdownMenu = styled(DropdownMenu)`
  &.dropdown-menu {
    padding-top: 0;
    padding-bottom: 0;
  }

  &.show {
    transform: translate3d(-90px, 45px, 0px) !important;
  }
`;

export const StyledAccountIcon = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  flex-wrap: nowrap;
  vertical-align: baseline;
  line-height: 1em;
  fill: ${({ background }) => (background ? 'white' : '#6E7A8A')};

  svg {
    width: 30px;
    margin-right: 0.5rem;
  }
`;

export const StyledAccountName = styled.span`
  color: ${({ background }) => (background ? 'white' : '#42526e')};
  font-size: 15px;
  line-height: 1em;
  padding-top: 0.19rem;
`;

export const StyledDropdownItem = styled(DropdownItem)`
  font-size: 0.75rem;
  line-height: 27px;
  display: block;
  padding: 0.25rem 1rem;
  color: #525f7f;
  outline: 0 !important;

  &:hover {
    background-color: rgba(14, 30, 37, 0.03);
    text-decoration: none;
  }

  &:active {
    color: #42526e;
  }

  a {
    width: 150px;
    color: inherit;
    text-decoration: none;
    white-space: nowrap;
  }
`;
