import React from 'react';
import styled from 'styled-components';

export const StyledFooter = styled(({ slim, ...props }) => <div {...props} />)`
  text-align: center;
  font-family: 'Graphik';
  background: #1f3e54;
  color: #fff;
  font-size: 1rem;
  text-align: left;
  padding-top: ${({ slim }) => (slim ? `0` : `80px`)};

  p {
    text-decoration: none;
    color: #fff;
    font-size: 0.9rem;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  a,
  li {
    text-decoration: none;
    color: #fff;
    font-size: 0.9rem;

    &:hover {
      color: #fff;
      transition: all 0.3s;
    }
  }
`;

export const StyledFooterTitle = styled.h3`
  color: white;
  margin-bottom: 1rem;
  font-size: 16px;
  font-weight: 600;
`;

export const StyledFooterLower = styled(({ slim, ...props }) => <section {...props} />)`
  margin-top: ${({ slim }) => (slim ? `0` : `70px`)};
  padding-top: 35px;
  padding-bottom: 35px;
  border-top: 1px solid rgba(255, 255, 255, 0.15);

  ul {
    padding-bottom: 1rem;
    @media (min-width: 480px) {
      padding-bottom: 0;
    }
  }

  li {
    display: inline-block;
    color: #98a2aa;
  }

  li:not(:first-child) {
    margin-left: 15px;
  }
`;

export const StyledFooterItems = styled.div``;

export const StyledFooterCopyright = styled.div``;
