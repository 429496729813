import styled from 'styled-components';
import { color } from '../../../helpers/style';
import Spinner from '../../../components/UI/Spinner';
import { Container } from 'reactstrap';

export const CourseSearchWrapper = styled.div`
  min-height: 100vh;
  background: ${color('soapstone')};
`;

export const StyledCoursesContainer = styled(Container)`
  padding-bottom: 3rem;
`;

export const StyledCoursesMeta = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 1.5rem 0;
  span {
    font-size: 0.8rem;
    font-weight: 500;
    padding-right: 0.5rem;
  }
`;

export const StyledSearchWrapper = styled.div`
  padding: 2rem 0;
  @media (min-width: 480px) {
    padding: 4rem 0;
  }
`;

export const StyledSpinner = styled(Spinner)`
  position: absolute;
  top: 90%;
`;
