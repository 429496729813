import React from 'react';
import styled, { css } from 'styled-components';

export const StyledStar = styled(({ editable, ...props }) => <span {...props} />)`
  display: inline-block;
  transition: all 0.5s ease;

  ${({ editable }) =>
    editable &&
    css`
      &:hover {
        transform: scale(1.5);
        cursor: pointer;
      }

      &:hover:not(:first-child) {
        margin-left: 0.2rem;
      }

      &:hover:not(:last-child) {
        margin-right: 0.2rem;
      }
    `}
`;
