import styled from 'styled-components';

export const StyledBlogPost = styled.article`
  margin-bottom: 2rem;
  max-width: 900px;
  margin: 0 auto;

  @media (min-width: 480px) {
    padding: 0 40px;
  }

  h1 {
    font-weight: 700;
    font-size: 2rem;
  }
  h2 {
    margin: 60px 0 30px;
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: -0.2px;
    color: black;
    padding-bottom: 0;
  }
  h3 {
    font-size: 1.7rem;
    margin: 30px 0 0;
    padding-bottom: 0;
    color: black;
  }
  p {
    font-size: 1.1rem;
    line-height: 30px;
    margin-top: 30px;
    color: black;
    font-weight: 400;
  }

  small {
    font-family: 'Graphik', sans-serif;
  }
  ul {
    margin-top: 15px;
    padding: 0.5rem 0;
    padding-left: 1.2rem;
  }
  li {
    font-size: 1.15rem;
    line-height: 30px;
    color: black;
    font-weight: 300;
    list-style-type: inherit;
  }
  img {
    width: 70%;
    box-shadow: rgba(23, 43, 99, 0.24) 0 0px 20px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  pre {
    margin: 20px 0 0;
    font-size: 0.5rem;
    background: #f4f4f4;
    border: 1px solid #ddd;
    border-left: 3px solid #f36d33;
    color: #666;
    page-break-inside: avoid;
    font-family: monospace;
    font-size: 15px;
    line-height: 1.6;
    margin-bottom: 1.6em;
    max-width: 100%;
    overflow: auto;
    padding: 1em 1.5em;
    display: block;
    word-wrap: break-word;
  }

  code {
    color: #666;
    background: #f4f4f4;
  }

  blockquote {
    background: rgba(36, 179, 115, 0.2);
    padding: 25px 35px;
    margin-top: 30px;

    p {
      color: #24b373;
      font-size: 1.1rem;
      line-height: 28px;
      font-weight: 500;
      margin: 0;
    }
  }
`;

export const StyledBlogImage = styled.img`
  height: auto;
`;

export const StyledBlogSection = styled.section`
  overflow-y: inherit;
  margin-bottom: 4rem;
  margin-top: 2rem;
`;
