import styled from 'styled-components';
import FormWrapper from '../../styles/Form';

export const LoginFormWrapper = styled(FormWrapper)`
  width: 100%;
  @media (min-width: 480px) {
    min-width: 450px;
  }

  input {
    appearance: none;
    -webkit-appearance: none;
    padding: 10px 12px;
    font-weight: 400;
    box-shadow: inset 0 1px 3px 0 rgba(33, 34, 36, 0.07);
    transition: border 0.15s ease-in-out;
    vertical-align: middle;
  }

  label {
    margin-bottom: 1rem;
    @media (min-width: 480px) {
      margin-bottom: 1.8rem;
    }
  }

  .new-account {
    span {
      font-weight: 400;
    }
  }
`;

export const StyledButtonGroup = styled.div`
  button:first-child {
    margin-bottom: 1rem;
    margin-top: 2rem;
  }
  img {
    margin-right: 1rem;
  }
`;
