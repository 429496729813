import styled from 'styled-components';
import { LeadText } from '../styles/Text';
import Icon from '../UI/Icon';

export const StyledBenefitBox = styled.div`
  /* divclass="single-services single-feature-hover gray-light-bg single-feature text-center p-5 h-100" */
  text-align: center;
  padding: 3rem;
  height: 100%;
  z-index: 0;
  position: relative;
  background: #ffffff;
  box-shadow: 0 0 0 1px #ebebeb;
  box-sizing: border-box;
  -webkit-transition: box-shadow 0.3s ease, z-index 0s 0.3s ease, -webkit-transform 0.3s ease;
  transition: box-shadow 0.3s ease, z-index 0s 0.3s ease, -webkit-transform 0.3s ease;
  transition: box-shadow 0.3s ease, transform 0.3s ease, z-index 0s 0.3s ease;
  transition: box-shadow 0.3s ease, transform 0.3s ease, z-index 0s 0.3s ease, -webkit-transform 0.3s ease;

  &:hover {
    z-index: 1;
    box-shadow: 0 8px 50px rgba(0, 0, 0, 0.2);
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
    border-radius: 4px;
    -webkit-transition: box-shadow 0.1s ease, z-index 0s 0.1s ease, -webkit-transform 0.1s ease;
    transition: box-shadow 0.1s ease, z-index 0s 0.1s ease, -webkit-transform 0.1s ease;
    transition: box-shadow 0.1s ease, transform 0.1s ease, z-index 0s 0.1s ease;
    transition: box-shadow 0.1s ease, transform 0.1s ease, z-index 0s 0.1s ease, -webkit-transform 0.3s ease;
  }
`;

export const StyledBenefitContent = styled.div`
  padding-top: 1rem;
`;

export const StyledBenefitText = styled(LeadText)`
  font-size: 0.95rem;
`;

export const StyledBenefitTitle = styled.h5`
  margin-bottom: 0.5rem;
`;

export const StyledBenefitIcon = styled(Icon)`
  font-size: 22px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
`;
