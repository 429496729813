import React from 'react';
import styled from 'styled-components';
import Button from '../UI/Button';
import { Form } from 'formik';
import { color } from '../../helpers/style';

export const FormWrapper = styled(({ shadow, ...props }) => <Form {...props} />)`
  box-shadow: ${props => (props.shadow ? '0 0 5px 3px rgba(0, 0, 0, 0.05)' : 'none')};
  background: ${color('white')};
  width: auto;
  margin: 0 auto;
  border-radius: 5px;
  text-align: center;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 600;

  label {
    display: block;
    margin-bottom: 1.8rem;
    text-align: left;
    font-weight: 400;
  }
  input,
  textarea,
  select {
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid ${color('mercury')};
    border-radius: 5px;
    margin-top: 3px;
    color: ${color('alabaster')};
    &:focus {
      outline: 0;
      border-color: ${color('blueRise')};
    }
    &::placeholder {
      color: ${color('mercury')};
      font-size: 0.9rem;
    }
  }
  button,
  input[type='submit'] {
    width: 100%;
    background: ${color('blueSea')};
    border-radius: 5px;
    color: ${color('white')};
    border: 0;
    font-size: 0.95rem;
    font-weight: 600;
    padding: 0.6rem 1.2rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background: ${color('matisse')};
    }
  }
  fieldset {
    border: 0;
    padding: 0;

    &[disabled] {
      opacity: 0.5;
    }
    &[aria-busy='true']::before {
      background-size: 50% auto;
    }
  }
  .form-title {
    padding-bottom: 1.5rem;
    font-size: 1.4rem;
    color: #1d2c4c;
    font-weight: bold;
  }
  .delete {
    background: #fb4242;
    border-radius: 5px;
    color: white;
    border: 0;
    font-size: 1rem;
    font-weight: 600;
    padding: 0.5rem 1.2rem;
    text-transform: uppercase;
    cursor: pointer;
  }
  .account-actions {
    padding-top: 2.5rem;
    text-align: left;
    color: #202a34;

    a {
      color: #202a34;
    }
    span {
      font-weight: 400;
    }
  }
`;

export const StyledSubscrbibeForm = styled.div`
  padding-top: 1rem;
`;
export const StyledSubscribeGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  input {
    width: 100%;
    padding: 0.5rem;
    font-size: 0.9rem;
    border: 2px solid #eff3f5;
    border-radius: 5px;
    margin-bottom: 1rem;
    text-align: center;
    @media (min-width: 480px) {
      width: 300px;
      margin-bottom: 0;
      text-align: left;
    }
    &:focus {
      outline: 0;
      border-color: #2196f3;
    }
    &::placeholder {
      color: #b1b1b1;
      font-size: 1rem;
    }
  }
`;
export const StyledSubscribeinput = styled.input``;

export const StyledSubscribebutton = styled(Button)`
  padding: 0.9rem 1.8rem;
  @media (min-width: 480px) {
    margin-left: 1rem;
  }
`;

export const StyledSubscribeAlert = styled.p`
  padding-top: 1rem;
  color: white;
`;

export default FormWrapper;

// @media (min-width: 480px) {
//   min-width: ${props => props.width};
// }

// border: 5px solid ${color('white')};
