import styled from 'styled-components';

export const StyledHelp = styled.section`
  padding-top: 3rem;
  padding-bottom: 3rem;
`;

export const StyledHelpHeader = styled.div`
  h1 {
    font-size: 3.5rem;
    font-weight: 600;
    padding-bottom: 1rem;
  }

  p {
    margin-bottom: 3rem;
  }
`;

export const StyledQA = styled.div`
  padding-bottom: 3rem;

  h3 {
    padding-bottom: 0.5rem;
  }

  p {
    line-height: 24px;
    width: 85%;
    font-size: 1rem;
  }
`;
