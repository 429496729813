// import { concatenateStrings } from '../helpers/helpers';

export const STATUS_ACTIVE = 'ACTIVE';
export const STATUS_COMPLETED = 'COMPLETED';
export const STATUS_CONFIRMED = 'CONFIRMED';
export const STATUS_CANCELLED = 'CANCELLED';
export const BLOG_API_URL = `https://cdn.contentful.com/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE_ID}/entries?access_token=${process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN}&content_type=blogPost`;

export const defaultMetaTags = {
  title: 'Learnstability: Online Course Search, Community Reviews & More',
  description:
    'Learnstability brings you the best online courses all in one place. Course reviews, latest discounts and much more. ',
  canonical: 'https://www.learnstability.com',
};

export const blogMetaTags = {
  title: 'Blog | Learnstability',
  description:
    'The Learnstability Blog features regular articles on Web Development, life and technology. We aim to deep dive into complex topics in a fun and friendly way to help new developers learn more online.',
  canonical: 'https://www.learnstability.com/blog',
};

export const loginMetaTags = {
  title: 'Login | Learnstability',
  description: 'Login to your Learnstability account to leave reviews and access your saved courses.',
  canonical: 'https://www.learnstability.com/login',
};

export const coursesMetaTags = {
  title: 'Course Search Results | Learnstability',
  description: 'Search results of the best online courses to help you find what you are looking for.',
  canonical: 'https://www.learnstability.com/course',
};

export const courseSearchMetaTags = {
  title: 'Course Search | Learnstability',
  description: 'Search for online courses from platforms such as Udemy, Pluralsight, Treehouse and many more.',
  canonical: 'https://www.learnstability.com/course-search',
};

export const privacyPolicyMetaTags = {
  title: 'Privacy Policy | Learnstability',
  description:
    'View the entire Learnstability privacy policy information. Your privacy is important to us. It is Learnstabilitys policy to respect your privacy',
  canonical: 'https://www.learnstability.com/privacy-policy',
};

export const signupMetaTags = {
  title: 'Sign Up | Learnstability',
  description: 'Sign up to an account today to leave course reviews and share your experiences with the community.',
  canonical: 'https://www.learnstability.com/signup',
};

export const termsMetaTags = {
  title: 'Terms And Conditions | Learnstability',
  description:
    'View the Learnstability terms and service. By accessing the website at https://www.lernstability.com, you are agreeing to be bound by these terms of service.',
  canonical: 'https://www.learnstability.com/terms-and-conditions',
};

export const subscribeMetaTags = {
  title: 'Subscribe | Learnstability',
  description:
    'Want to learn how to build websites or work in a real development team? Subscribe today to recieve our premium posts we dont share in public and kickstart your learning.',
  canonical: 'https://www.learnstability.com/subscribe',
};

export const dashboardMetaTags = {
  title: 'Dashboard | Learnstability',
  description:
    'Want to learn how to build websites or work in a real development team? Subscribe today to recieve our premium posts we dont share in public and kickstart your learning.',
  canonical: 'https://www.learnstability.com/subscribe',
};

export const wishListMetaTags = {
  title: 'Wish List | Learnstability',
  description:
    'Want to learn how to build websites or work in a real development team? Subscribe today to recieve our premium posts we dont share in public and kickstart your learning.',
  canonical: 'https://www.learnstability.com/subscribe',
};

export const accountMetaTags = {
  title: 'Account | Learnstability',
  description:
    'Want to learn how to build websites or work in a real development team? Subscribe today to recieve our premium posts we dont share in public and kickstart your learning.',
  canonical: 'https://www.learnstability.com/account',
};

export const helpMetaTags = {
  title: 'Help | Learnstability',
  description:
    'Want to learn how to build websites or work in a real development team? Subscribe today to recieve our premium posts we dont share in public and kickstart your learning.',
  canonical: 'https://www.learnstability.com/account',
};
