import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as regularStar } from '@fortawesome/free-regular-svg-icons';
import { StyledStar } from './styled';

const renderStar = isFull => {
  const icon = isFull ? faStar : regularStar;
  return <FontAwesomeIcon icon={icon} size="lg" />;
};

const Star = ({ isFull, onClick, editable }) => (
  <StyledStar editable={editable} onClick={onClick}>
    {renderStar(isFull)}
  </StyledStar>
);

export default Star;
