import React from 'react';
import { UncontrolledDropdown } from 'reactstrap';
import chevronDown from '../../../public/static/image/icons/chevron-down.svg';
import { StyledDropdownToggle, StyledDropdownMenu, StyledDropdownItem } from './styled';

const Dropdown = () => (
  <UncontrolledDropdown>
    <StyledDropdownToggle>
      <span>Most Relevant</span>
      <img src={chevronDown} alt="Updown Icon" />
    </StyledDropdownToggle>
    <StyledDropdownMenu>
      <StyledDropdownItem>Highest Rated</StyledDropdownItem>
      <StyledDropdownItem>Most Popular</StyledDropdownItem>
      <StyledDropdownItem>Most Revewed</StyledDropdownItem>
    </StyledDropdownMenu>
  </UncontrolledDropdown>
);

export default Dropdown;
