import { css } from 'styled-components';

export const bodyBackground = ({ theme }) => theme.bodyBackground;
export const bodyColor = ({ theme }) => theme.bodyColor;

export const fontFamily = (variant = 'base') => ({ theme }) => theme.fontFamilies[variant];
export const fontSize = (size = 'base') => ({ theme }) => theme.fontSizes[size];
export const fontWeight = (weight = 'base') => ({ theme }) => theme.fontWeights[weight];
export const lineHeight = (size = 'base') => ({ theme }) => theme.lineHeights[size];

export const resetLinkStyle = () => css`
  font-weight: ${fontWeight('normal')};
  background-image: none;

  &:hover {
    background-image: none;
  }
`;
