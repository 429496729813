import React, { Fragment } from 'react';
import LoginForm from '../../components/Forms/LoginForm';
import Layout from '../../components/Layout';
import { connect } from 'react-redux';
import learnstabilityLogo from '../../public/static/image/ls6.svg';
import { Redirect } from 'react-router-dom';
import { loginMetaTags } from '../../helpers/constants';
import { createStructuredSelector } from 'reselect';
import { selectCurrentUser } from '../../redux/user/user.selectors';
import {
  StyledLogin,
  StyledLeftContent,
  StyledLoginTitle,
  StyledLoginLeft,
  StyledLogoWrapper,
  StyledLogo,
  StyledLoginRight,
} from './styled';

const LoginPage = ({ currentUser }) => (
  <Fragment>
    {currentUser && <Redirect to="/course-search" />}
    <Layout metaTags={loginMetaTags}>
      <StyledLogin>
        <StyledLoginLeft>
          <StyledLeftContent>
            <StyledLogoWrapper>
              <StyledLogo src={learnstabilityLogo} alt="Learnstability Logo" />
            </StyledLogoWrapper>
            <StyledLoginTitle>Welcome Back!</StyledLoginTitle>
            <LoginForm />
          </StyledLeftContent>
        </StyledLoginLeft>
        <StyledLoginRight />
      </StyledLogin>
    </Layout>
  </Fragment>
);

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

export default connect(mapStateToProps)(LoginPage);
