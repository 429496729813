import React from 'react';
import Navbar from '../../components/Navigation';
import Layout from '../../components/Layout';
import Footer from '../../components/Footer';
import { Row, Container, Col } from 'reactstrap';
import { helpMetaTags } from '../../helpers/constants';
import { StyledHelp, StyledHelpHeader, StyledQA } from './styled';

const HelpPage = () => (
  <Layout metaTags={helpMetaTags}>
    <Navbar />
    <StyledHelp>
      <Container>
        <Row>
          <Col>
            <StyledHelpHeader>
              <h1>FAQ</h1>
              <p>
                Our goal is to help students find the best online courses, these are some of our most popular
                questions.
              </p>
            </StyledHelpHeader>
          </Col>
        </Row>
        <StyledQA>
          <h3>How do I leave a course review? </h3>
          <p>
            To leave a course review simply create an account, visit a course page and click leave reviews. Reviews are
            periodically updated and reviewed.
          </p>
        </StyledQA>

        <StyledQA>
          <h3>Why is my review not showing? </h3>
          <p>Reviews are updated regularly by our reviewers to check for integrity and helpfulness.</p>
        </StyledQA>

        <StyledQA>
          <h3>Why should I leave a review?</h3>
          <p>
            Learnstability... for now is a platform made to help developers find the best quality courses online,
            backed up by the community. Your reward is a sense of pride that you got your voice herd and helped someone
            else.
          </p>
        </StyledQA>

        <StyledQA>
          <h3>There is a course I want to review but Its not here?</h3>
          <p>We are more than happy to add new courses where we can, get in touch and send us the course details.</p>
        </StyledQA>

        <StyledQA>
          <h3>How can I support learnstability?</h3>
          <p>
            Share course reviews, and follow us on social media. Your support is a massive help as we are starting
            out.>
          </p>
        </StyledQA>

        <StyledQA>
          <h3>I have found a bug or issue with the platform?</h3>
          <p>
            Oh no.. we aim for a flawless experience when leaving reviews, so please let us know how the issue happened
            here.
          </p>
        </StyledQA>

        <StyledQA>
          <h3>I want to request a feature?</h3>
          <p>Great, we love community involvement, get in touch and let us know what it is.</p>
        </StyledQA>
      </Container>
    </StyledHelp>

    <Footer />
  </Layout>
);

export default HelpPage;
