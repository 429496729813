import React from 'react';
import { StyledBurger } from './styled';

const Burger = ({ open, setOpen, background }) => (
  <StyledBurger open={open} onClick={() => setOpen(!open)} background={background}>
    <div />
    <div />
    <div />
  </StyledBurger>
);

export default Burger;
