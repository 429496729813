import { tint } from 'polished';
import { themeColors } from './colors';

const { success, danger, info, warning } = themeColors;

const alert = {
  backgroundColor: {
    success: tint(0.97, success),
    danger: tint(0.97, danger),
    info: tint(0.97, info),
    warning: tint(0.97, warning),
  },
  borderColor: {
    success: tint(0.7, success),
    danger: tint(0.7, danger),
    info: tint(0.7, info),
    warning: tint(0.7, warning),
  },
};

export default alert;
