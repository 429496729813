import React from 'react';
import { StyledAlert, StyledIcon, StyledButton, StyledAlertContent } from './styled';
import { ReactComponent as IconSuccess } from '../../../public/static/image/icons/success.svg';
import { ReactComponent as IconDanger } from '../../../public/static/image/icons/danger.svg';
import { ReactComponent as IconWarning } from '../../../public/static/image/icons/warning.svg';
import { ReactComponent as IconInfo } from '../../../public/static/image/icons/info.svg';
import { ReactComponent as Cross } from '../../../public/static/image/icons/cross.svg';

const icons = {
  success: IconSuccess,
  danger: IconDanger,
  warning: IconWarning,
  info: IconInfo,
};

const Alert = ({ color, children, isOpen, toggle, className }) => {
  const Icon = icons[color];

  return (
    <StyledAlert
      color={color}
      isOpen={isOpen}
      className={toggle ? `alert-dismissible ${className}` : className}
      data-test-id="Alert"
    >
      {toggle && <StyledButton color={color} icon={Cross} aria-label="Close" onClick={toggle} />}
      <div>
        <StyledIcon as={Icon} data-test-id="Alert-icon" />
      </div>
      <StyledAlertContent data-test-id="Alert-content">{children}</StyledAlertContent>
    </StyledAlert>
  );
};

export default Alert;
