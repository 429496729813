import styled from 'styled-components';
import { color, spacer } from '../../../helpers/style';
import Modal from '../../UI/Modal';

export const StyledCourseReviewChartContainer = styled.div`
  background: white;
  border-radius: 5px;
  border: solid 1px #d8d5d5;
  background-color: #fff;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  padding: 1rem;
`;

export const StyledModal = styled(Modal)`
  @media (min-width: 576px) {
    &.modal-dialog {
      max-width: 600px;
    }
  }

  .modal-content {
    border-radius: 5px;
    border: 0px;
  }

  .modal-title {
    line-height: 24px;
  }
  .modal-header {
    display: flex;
    align-items: center;
    border-bottom: 0;
    .close {
      padding: 0;
      padding-right: ${spacer};
    }
  }
`;

export const StyledReviewSummary = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px ${color('mineShaft')};
  padding-bottom: ${spacer};
  background-color: #fff;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  h3 {
    font-size: 1.3rem;
  }
  button {
    width: auto;
    background: ${color('blueSea')};
    font-size: 0.85rem;
    box-shadow: none;
    letter-spacing: 0px;
    font-weight: 400;
    padding: 0.5rem 1rem;
    &:hover,
    &:focus {
      background: ${color('matisse')};
      outline: 0;
      box-shadow: none;
    }
  }
`;

export const StyledModalHeader = styled.span`
  font-size: 1.2rem;
  font-weight: 600;
  color: ${color('alabaster')};
`;
