import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { StyledSalesImageBlock, SalesImageContent, SalesImageButton } from './styled';

const SalesImageBlock = ({ title, children, img, imageAlt, position, button, buttonUrl }) => (
  <StyledSalesImageBlock className="ptb-100 gray-light-bg">
    <Container>
      {position === 'right' ? (
        <Row className="justify-content-between align-items-center">
          <Col lg={5} md={6}>
            <img src={img} alt="Community" className="img-fluid" />
          </Col>
          <Col lg={{ span: 5, offset: 1 }} md={6}>
            <SalesImageContent>{children}</SalesImageContent>
            {button && <SalesImageButton href={buttonUrl}>Explore Reviews</SalesImageButton>}
          </Col>
        </Row>
      ) : (
        <Row className="justify-content-between align-items-center">
          <Col lg={6} md={6}>
            <SalesImageContent>{children}</SalesImageContent>
          </Col>
          <Col lg={6} md={6}>
            <img src={img} alt="Developers" className="img-fluid" />
            {button && <SalesImageButton>Explore Reviews</SalesImageButton>}
          </Col>
        </Row>
      )}
    </Container>
  </StyledSalesImageBlock>
);

export default SalesImageBlock;
