import React from 'react';
import * as Yup from 'yup';
import Error from '../../Error';
import FormError from '../../styles/FormError';
import Button from '../../../components/UI/Button';
import { Link } from 'react-router-dom';
import { Formik, Field } from 'formik';
import { connect } from 'react-redux';
import { googleSignInStart, emailSignInStart } from '../../../redux/user/user.actions';
import { LoginFormWrapper } from './styled';
import GoogleIcon from '../../../public/static/image/icons/google.svg';
import { StyledButtonGroup } from './styled';

const LoginForm = ({ emailSignInStart, isLoading, error, googleSignInStart }) => {
  const initialValues = {
    email: '',
    password: '',
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email()
      .required(),
    password: Yup.string()
      .min(8)
      .max(16)
      .required(),
  });

  const onSubmit = async ({ email, password }) => {
    emailSignInStart(email, password);
  };

  return (
    <>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
        {({ errors, touched, handleChange }) => (
          <LoginFormWrapper width="500px" shadow={false}>
            <fieldset>
              <Error error={error} />
              <label htmlFor="email">
                Email
                <Field type="text" name="email" placeholder="yourname@example.com" onChange={handleChange} />
                {errors.email && touched.email ? <FormError>{errors.email}</FormError> : null}
              </label>
              <label htmlFor="password">
                Password
                <Field
                  type="password"
                  autoComplete="false"
                  name="password"
                  onChange={handleChange}
                  placeholder="Password"
                />
                {errors.password && touched.password ? <FormError>{errors.password}</FormError> : null}
              </label>

              <StyledButtonGroup>
                <Button disabled={isLoading} loading={isLoading} type="submit">
                  Log In
                </Button>

                <Button type="button" icon={GoogleIcon} onClick={googleSignInStart}>
                  Log In With Google
                </Button>
              </StyledButtonGroup>
            </fieldset>

            <div className="account-actions">
              <Link to="/request-reset">Forgot password?</Link>

              <div className="new-account">
                <span>Don't have an account?</span>
                <Link to="/signup"> Sign up</Link>
              </div>
            </div>
          </LoginFormWrapper>
        )}
      </Formik>
    </>
  );
};

const mapStateToProps = state => ({
  isLoading: state.user.isLoading,
  error: state.user.error,
});

const mapDispatchToProps = dispatch => ({
  googleSignInStart: () => dispatch(googleSignInStart()),
  emailSignInStart: (email, password) => dispatch(emailSignInStart({ email, password })),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
