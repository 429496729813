import React from 'react';
import Star from '../UI/Star';
import { StarWrapper } from './styled';

const Stars = ({ count, handleClick, editable }) => (
  <StarWrapper>
    {[...Array(5).keys()].map(i => {
      return <Star key={i} isFull={i < count} editable={editable} onClick={() => handleClick(i + 1)} />;
    })}
  </StarWrapper>
);

Stars.defaultProps = {
  count: 1,
  handleClick: e => e,
};

export default Stars;
