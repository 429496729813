import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { StyledSocialImage } from './styled';
import udemyImage from '../../public/static/image/social/udemy-logo.svg';
import treehouseImage from '../../public/static/image/social/treehouse-logo.svg';
import courseraImage from '../../public/static/image/social/coursera-logo.svg';
import pluralsightImage from '../../public/static/image/social/pluralsight-logo.svg';

const SocialProof = () => {
  const socialIcons = [
    {
      name: 'Udemy',
      image: udemyImage,
    },
    {
      name: 'Treehouse',
      image: treehouseImage,
    },
    {
      name: 'Coursera',
      image: courseraImage,
    },
    {
      name: 'Pluralsight',
      image: pluralsightImage,
    },
  ];

  return (
    <Container>
      <Row>
        {socialIcons.map((company, index) => (
          <Col xs={6} md={3} key={index}>
            <StyledSocialImage>
              <img src={company.image} alt={company.name} />
            </StyledSocialImage>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default SocialProof;
