import { colors, themeColors } from './colors';
import { fontSizes, fontWeights, lineHeights } from './type';
import { spacer, gridBreakpoints, gridGutterWidths } from './grid';
import { borderWidth, borderColor, borderStyle, borderRadii, boxShadows } from './box';
import { fontFamilies } from './fonts';
import alert from './alert';

const theme = {
  colors,
  themeColors,
  spacer,
  gridBreakpoints,
  gridGutterWidths,
  borderWidth,
  borderColor,
  borderStyle,
  borderRadii,
  boxShadows,
  transitions: {
    base: 'all 0.2s ease-in-out',
    fade: 'opacity 0.15s linear',
    collapse: 'height 0.35s ease',
  },
  fontFamilies,
  fontSizes,
  fontWeights,
  lineHeights,
  alert,
};

export default theme;
