import React, { useEffect } from 'react';
import Navbar from '../../../components/Navigation';
import Footer from '../../../components/Footer';
import Layout from '../../../components/Layout';
import SearchForm from '../../../components/Forms/SearchForm';
import CourseCategories from '../../../components/Course/CourseCategories';
import { Row, Col, Container } from 'reactstrap';
import { courseSearchMetaTags } from '../../../helpers/constants';
import {
  StyledCourseSearch,
  StyledSearchHero,
  StyledSearchHeroContent,
  StyledCategoriesWrapper,
  StyledCategoriesSection,
  StyledCategoriesHeading,
} from './styled';
import { categories, topCourses } from '../../../helpers/categories';

const CourseSearchLanding = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout metaTags={courseSearchMetaTags}>
      <div>
        <StyledSearchHero>
          <Navbar background />

          <StyledSearchHeroContent>
            <Container>
              <Row>
                <Col xs={12}>
                  <StyledCourseSearch>
                    <h2>Find your next online course today.</h2>
                    <SearchForm redirect />
                  </StyledCourseSearch>
                </Col>
              </Row>
            </Container>
          </StyledSearchHeroContent>
        </StyledSearchHero>

        <StyledCategoriesWrapper>
          <StyledCategoriesSection>
            <Container>
              <Row>
                <Col xs={12}>
                  <StyledCategoriesHeading>Popular Categories</StyledCategoriesHeading>
                </Col>
              </Row>
              <CourseCategories categories={categories} />
            </Container>
          </StyledCategoriesSection>

          <StyledCategoriesSection>
            <Container>
              <Row>
                <Col xs={12}>
                  <StyledCategoriesHeading>Top Courses</StyledCategoriesHeading>
                </Col>
              </Row>
              <CourseCategories categories={topCourses} />
            </Container>
          </StyledCategoriesSection>
        </StyledCategoriesWrapper>
      </div>

      <Footer slim />
    </Layout>
  );
};

export default CourseSearchLanding;
