import styled from 'styled-components';

export const StyledCourseOverviewCard = styled.div`
  text-decoration: none;
  border-radius: 5px;
  transition: all 0.7s ease;
  margin-bottom: 1.5rem;
  transition: transform 0.2s ease-in-out;
  margin-bottom: 1rem;
  position: relative;
  border-radius: 5px;

  @media (min-width: 480px) {
    padding-bottom: 3.5rem;
  }

  blockquote {
    font-style: italic;
    text-align: center;
    line-height: 37px;

    padding-bottom: 1.5rem;
    font-size: 1.2rem;
    font-weight: 400;
    &:before {
      content: open-quote;
    }
    &:after {
      content: close-quote;
    }
  }

  h1 {
    font-weight: 600;
    font-size: 2.3rem;
    padding-bottom: 1rem;
    color: #212224;
  }

  h3 {
    font-size: 1.6rem;
    padding-bottom: 2rem;
    color: #212224;
  }

  p {
    font-weight: 400;
    line-height: 28px;
    font-size: 17px;
    &:not(:last-child) {
      padding-bottom: 2rem;
    }
  }

  ul {
    padding: 0rem 1rem;
    margin-bottom: 0;
  }
`;

export const StyledOverviewSection = styled.div`
  &:not(:last-child) {
    padding-bottom: 2rem;
  }
`;

export const StyledReviewIntro = styled.p`
  line-height: 26px;
  color: #3d414a;
`;

export const StyledCourseIntro = styled.div`
  padding-bottom: 2rem;
`;
