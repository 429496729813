import React from 'react';
// import styled from 'styled-components';
// import { fontFamily, fontWeight, textAlign, lineHeight, letterSpacing } from 'styled-system';
// import { base, themed } from '../../../theme/base';

// const TextWrapper = styled('p')(base, fontFamily, fontWeight, textAlign, lineHeight, letterSpacing, themed('Text'));

const Text = ({ content, ...props }) => <p {...props}>{content}</p>;

export default Text;
