import React, { Fragment } from 'react';
import Meta from '../Meta';

const Layout = ({ metaTags, children }) => {
  return (
    <Fragment>
      <Meta tags={metaTags} />
      <div className="layout">
        <main>{children}</main>
      </div>
    </Fragment>
  );
};

export default Layout;
