import styled from 'styled-components';
import { multiply, spacer } from '../../helpers/style';

export const StyledCallToAction = styled.section`
  background: linear-gradient(135deg, #0058b5 0%, #1fbce1 100%);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  padding: ${multiply(spacer, 8)} 0;
  position: relative;
  width: 100%;
  display: block;
  &:before {
    position: absolute;
    content: '';
    background: rgba(0, 67, 139, 0.85);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  p {
    color: white;
  }
`;
