import styled from 'styled-components';

export const StyledSocial = styled.div`
  padding: 1rem;
  box-shadow: none;
  border-radius: 5px;
  border: solid 1px #d8d5d5;
  background-color: #fff;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  margin-bottom: 2rem;

  .ti-twitter-alt {
    color: #1da1f2;
  }

  .ti-facebook {
    color: #4266b2;
  }

  .ti-linkedin {
    color: #0073b1;
  }

  a {
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    background: #fff;
    color: #2653ce;
    text-align: center;
    margin-bottom: 10px;
    -webkit-transition: ease all 0.3s;
    transition: ease all 0.3s;
    -webkit-text-decoration: none;
    text-decoration: none;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-between;
  }
`;
