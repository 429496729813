import ReviewActionTypes from './review.types';

export const fetchReviewsStart = () => ({
  type: ReviewActionTypes.FETCH_REVIEWS_START,
});

export const fetchReviewsSuccess = reviews => ({
  type: ReviewActionTypes.FETCH_REVIEWS_SUCCESS,
  payload: reviews,
});

export const fetchReviewsFailure = error => ({
  type: ReviewActionTypes.FETCH_REVIEWS_FAILURE,
  payload: error,
});
