import styled from 'styled-components';

export const StyledHero = styled.section`
  position: relative;
  width: 100%;
  display: block;
  background-image: url(${props => props.image});
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;

  &:before {
    position: absolute;
    content: '';
    background: linear-gradient(135deg, #0058b5 0%, #1fbce1 100%);
    ${({ transparentBg }) =>
      transparentBg &&
      `
      background: rgba(36, 88, 218, 0.93);
    `}
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
`;

export const StyledHeroContent = styled.div`
  position: relative;
  margin: 10rem 0 10rem 0;
  align-items: center;
  text-align: center;
  ${({ left }) =>
    left &&
    `
     text-align: left;
    `}
`;

export const StyledHeroTitle = styled.h1`
  color: white;
  font-weight: bold;
  margin-bottom: 2rem;
  font-size: 2rem;

  @media (min-width: 768px) {
    font-size: 2.5rem;
  }
`;

export const StyledHeroDescription = styled.p`
  color: white;
  line-height: 28px;
  width: 100%;
  margin: 0 auto;
  font-size: 1rem;
  margin-bottom: 1.5rem;

  ${({ left }) =>
    left &&
    `
     margin: initial;
    `}

  @media (min-width: 768px) {
    width: 70%;
    font-size: 1.2rem;
  }
`;

export const StyledHeroLogo = styled.img`
  margin-bottom: 5rem;
  width: 55%;
  @media (min-width: 480px) {
    width: 25%;
  }
`;
