import React, { Fragment } from 'react';
import {
  StyledBlogCard,
  StyledBlogTitle,
  StyledBlogMeta,
  StyledBlogMetaItem,
  StyledBlogImage,
  StyledPublishedDate,
  StyledStarRating,
} from './styled';
import { withRouter } from 'react-router-dom';
const BlogCard = ({ id, slug, imageUrl, title, description, tags, date, body, template, rating, history }) => {
  // const renderTags = tags && tags.map(tag => <StyledBlogTag key={tag.name}>#{tag.name}</StyledBlogTag>);

  const setPostMeta = () => {
    window.sessionStorage.setItem('courseTitle', title);
    window.sessionStorage.setItem('courseImage', imageUrl);
    window.sessionStorage.setItem('courseDesc', description);
    return history.push(`/post/${slug}`);
  };

  return (
    <Fragment>
      <StyledBlogCard onClick={setPostMeta}>
        <StyledBlogImage src={imageUrl} alt="Developer" />
        <StyledBlogTitle>{title}</StyledBlogTitle>
        <StyledBlogMeta>
          <StyledBlogMetaItem>{description}</StyledBlogMetaItem>
        </StyledBlogMeta>
        {rating && <StyledStarRating name="rate1" starCount={5} value={rating} />}
        {date && <StyledPublishedDate>{date}</StyledPublishedDate>}
      </StyledBlogCard>
    </Fragment>
  );
};

export default withRouter(BlogCard);
