import React, { Fragment, useState } from 'react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import FormError from '../../styles/FormError';
import Button from '../../../components/UI/Button';
import { StyledReviewForm, StyledTextarea, StyledButtonBlock } from './styled';
import { firestore } from '../../../firebase/firebase.utils';
import StarsInput from '../../Fields/StarsInput';
import { v4 as uuidv4 } from 'uuid';

const LeaveReviewForm = ({
  courseId,
  toggleWriteReview,
  setError,
  currentCourse,
  setSubmitted,
  currentUser: { displayName },
}) => {
  const [loading, setLoading] = useState();
  const [stars, setStars] = useState(3);

  const initialValues = {
    title: '',
    content: '',
    stars,
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(),
    content: Yup.string()
      .min(15)
      .required(),
  });

  const updateCourseData = (reviewScore, docRef) => {
    const randomId = uuidv4();
    try {
      firestore
        .collection('courses')
        .doc(docRef)
        .set(
          {
            reviewScores: {
              [`${randomId}`]: reviewScore,
            },
          },
          { merge: true },
        );
    } catch {
      console.log('Error');
    }
  };

  const onSubmit = async ({ title, content, stars }) => {
    setStars(stars);
    setLoading(true);
    try {
      await firestore.collection('reviews').add({
        title,
        content,
        rating: stars,
        courseId: courseId,
        reviewerName: displayName,
      });
      toggleWriteReview();
      updateCourseData(stars, currentCourse.docRef);
      window.scrollTo(0, 0);
      setSubmitted(true);
    } catch (error) {
      console.log(error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <Formik
        initialValues={initialValues}
        onSubmit={values => onSubmit(values, setStars)}
        validationSchema={validationSchema}
      >
        {({ errors, touched, handleChange, setFieldValue }) => (
          <Fragment>
            <StyledReviewForm width="500px" shadow={false}>
              <fieldset disabled={loading} aria-busy={loading}>
                <StarsInput fieldName="stars" onChange={handleChange} setFieldValue={setFieldValue} value={5} />

                <label htmlFor="title">
                  Title of your review
                  <Field
                    type="text"
                    name="title"
                    placeholder="Summary of your experience with this course."
                    onChange={handleChange}
                  />
                  {errors.title && touched.title ? <FormError>{errors.title}</FormError> : null}
                </label>

                <label htmlFor="content">
                  Your review
                  <StyledTextarea
                    type="text"
                    component="textarea"
                    autoComplete="false"
                    name="content"
                    onChange={handleChange}
                    placeholder="Explain in detail what the course was like, author, content quality, challenges."
                  />
                  {errors.content && touched.content ? <FormError>{errors.content}</FormError> : null}
                </label>
                <StyledButtonBlock>
                  <Button size="sm" disabled={loading} loading={loading}>
                    Submit your review
                  </Button>
                </StyledButtonBlock>
              </fieldset>
            </StyledReviewForm>
          </Fragment>
        )}
      </Formik>
    </Fragment>
  );
};

export default LeaveReviewForm;
