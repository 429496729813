import styled from 'styled-components';
import { color } from '../../../helpers/style';

export const StyledCourseReviewChart = styled.div`
  display: table;
`;

export const StyledFiller = styled.div`
  background: #ffcb2c;
  height: 100%;
  border-radius: inherit;
  transition: width 0.2s ease-in;
`;

export const StyledProgressLabel = styled.span`
  display: table-cell;
  white-space: nowrap;
  padding-right: 12px;
  vertical-align: middle;
  font-size: 1rem;
  padding-top: 5px;
  color: ${color('wildSand')};
`;

export const StyledProgressTotal = styled(StyledProgressLabel)`
  padding-left: 12px;
  text-align: center;
`;

export const StyledProgressBar = styled.div`
  display: table-row;
  padding-bottom: 12px;
  position: relative;
`;

export const StyledProgressWrapper = styled.div``;

export const StyledBar = styled.div`
  position: relative;
  height: 25px;
  width: 160px;
  border-radius: 5px;
  background: #e1e7ec;
  margin-top: 0.3rem;
  text-align: left;

  @media (min-width: 480px) {
    width: 350px;
  }
`;
