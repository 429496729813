import styled from 'styled-components';

export const StyledCategory = styled.div`
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  background: white;
  font-size: 1rem;
  padding: 1.2rem;
  height: 100%;
`;

export const StyledCategoryHeading = styled.h5`
  padding-bottom: 0.5rem;
  font-weight: 500;
`;

export const StyledCategories = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledCategoryList = styled.ul`
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
`;

export const StyledCategoryListItem = styled.li`
  list-style: none;
  margin-right: ${({ marginRight }) => (marginRight ? `1.5rem` : `0`)};
  padding-right: 1rem;
  a {
    cursor: pointer;
    color: #42526e;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const StyledCategoryIcon = styled.img`
  max-width: 23%;
  padding-bottom: 1.5rem;
`;
