import BlogActionTypes from './blog.types';

const INITIAL_STATE = {
  posts: null,
  error: null,
  isFetching: false,
};

const blogReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BlogActionTypes.FETCH_POSTS_START:
      return {
        ...state,
        isFetching: true,
      };
    case BlogActionTypes.FETCH_POSTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        posts: action.payload,
      };
    case BlogActionTypes.FETCH_POSTS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default blogReducer;
