import React from 'react';
import { Container, Row } from 'reactstrap';
import { StyledFooter, StyledFooterLower, StyledFooterItems, StyledFooterCopyright } from './styled';
import { Link } from 'react-router-dom';

const Footer = ({ slim }) => (
  <StyledFooter data-sticky-footer="true" slim>
    <StyledFooterLower slim>
      <Container>
        <Row className="justify-content-between mr-0 ml-0 align-items-center">
          <StyledFooterItems>
            <ul>
              <li>
                <Link to="/">Support</Link>
              </li>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/terms-and-conditions">Terms & Conditions</Link>
              </li>
            </ul>
          </StyledFooterItems>
          <StyledFooterCopyright>
            <span>© 2020 Learnstability</span>
          </StyledFooterCopyright>
        </Row>
      </Container>
    </StyledFooterLower>
  </StyledFooter>
);

export default Footer;
