import React from 'react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import FormError from '../../styles/FormError';
import { StyledSearchForm, StyledSearchInput, StyledButton } from './styled';
import searchIcon from '../../../public/static/image/icons/search.svg';
import { withRouter } from 'react-router-dom';

const SearchForm = ({ loading, searchTerm, redirect, history, setSearchTerm, align }) => {
  const initialValues = {
    searchValue: searchTerm || '',
  };

  const validationSchema = Yup.object().shape({
    searchValue: Yup.string().max(30),
  });

  const onSubmit = async ({ searchValue }) => {
    if (redirect) {
      const serchString = searchValue.replace(/ +/g, '+');
      history.push({
        pathname: '/course-search/search/',
        search: `?q=${serchString}`,
      });
    }
    setSearchTerm(searchValue);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      {({ isSubmitting, handleChange, errors, touched }) => (
        <StyledSearchForm shadow={true} align={align}>
          <fieldset disabled={loading} aria-busy={loading}>
            <label htmlFor="searchValue">
              <StyledSearchInput>
                <Field type="text" name="searchValue" placeholder="e.g JavaScript" />
                <StyledButton type="submit">
                  <img src={searchIcon} alt="Search Icon" />
                </StyledButton>
              </StyledSearchInput>
              {errors.searchValue && touched.searchValue ? <FormError>{errors.searchValue}</FormError> : null}
            </label>
          </fieldset>
        </StyledSearchForm>
      )}
    </Formik>
  );
};

export default withRouter(SearchForm);
