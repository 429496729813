import { css } from 'styled-components';
import { stripUnit } from 'polished';

export const gridGutterWidth = breakpoint => ({ theme }) => theme.gridGutterWidths[breakpoint];
export const gridBreakpoint = breakpoint => ({ theme }) => theme.gridBreakpoints[breakpoint];

export const justifyContent = ({ alignment }) => {
  switch (alignment) {
    case 'center':
      return css`
        justify-content: center;
      `;

    case 'left':
      return css`
        justify-content: flex-start;
      `;

    case 'right':
      return css`
        justify-content: flex-end;
      `;

    case 'full-width':
      return css`
        justify-content: stretch;
      `;

    case 'space-between':
      return css`
        justify-content: space-between;
      `;

    case 'space-around':
      return css`
        justify-content: space-around;
      `;

    case 'space-evenly':
      return css`
        justify-content: space-evenly;
      `;

    default:
      return null;
  }
};

export const breakpointNext = breakpoint => ({ theme }) => {
  const breakpointNames = Object.keys(theme.gridBreakpoints);
  const index = breakpointNames.indexOf(breakpoint);
  return index !== -1 && index < breakpointNames.length - 1 ? breakpointNames[index + 1] : null;
};

export const breakpointMax = breakpoint => ({ theme }) => {
  const next = breakpointNext(breakpoint)({ theme });

  if (next) {
    const [width, unit] = stripUnit(theme.gridBreakpoints[next], true);
    return `${width - 0.02}${unit}`;
  }

  return null;
};

export const breakpointInfix = breakpoint => ({ theme }) =>
  theme.gridBreakpoints[breakpoint] ? `-${breakpoint}` : null;

export const mediaBreakpointUp = breakpoint => (...args) => ({ theme }) => {
  const min = theme.gridBreakpoints[breakpoint];

  if (min) {
    return css`
      @media (min-width: ${min}) {
        ${css(...args)};
      }
    `;
  }

  return css(...args);
};

export const mediaBreakpointDown = breakpoint => (...args) => ({ theme }) => {
  const max = breakpointMax(breakpoint)({ theme });

  if (max) {
    return css`
      @media (max-width: ${max}) {
        ${css(...args)};
      }
    `;
  }

  return css(...args);
};

export const mediaBreakpointBetween = (lowerBreakpoint, upperBreakpoint) => (...args) => ({ theme }) => {
  const min = theme.gridBreakpoints[lowerBreakpoint];
  const max = breakpointMax(upperBreakpoint)({ theme });

  if (min && max) {
    return css`
      @media (min-width: ${min}) and (max-width: ${max}) {
        ${css(...args)};
      }
    `;
  }

  if (!max) {
    return mediaBreakpointUp(lowerBreakpoint)(...args)({ theme });
  }

  if (!min) {
    return mediaBreakpointDown(upperBreakpoint)(...args)({ theme });
  }

  return null;
};

export const mediaBreakpointOnly = breakpoint => (...args) => ({ theme }) => {
  const min = theme.gridBreakpoints[breakpoint];
  const max = breakpointMax(breakpoint)({ theme });

  if (min && max) {
    return css`
      @media (min-width: ${min}) and (max-width: ${max}) {
        ${css(...args)};
      }
    `;
  }

  if (!max) {
    return mediaBreakpointUp(breakpoint)(...args)({ theme });
  }

  if (!min) {
    return mediaBreakpointDown(breakpoint)(...args)({ theme });
  }

  return null;
};
