import React from 'react';
import PropTypes from 'prop-types';
import { StyledModal, StyledModalHeader, StyledModalBody, StyledModalFooter } from './styled';

const Modal = ({ className, isOpen, toggle, body, header, footer, backdrop, size, headingTag, alert }) => (
  <StyledModal isOpen={isOpen} toggle={toggle} className={className} backdrop={backdrop} size={size}>
    {alert && <h4>ALERT</h4>}
    <StyledModalHeader tag={headingTag} toggle={toggle}>
      {header}
    </StyledModalHeader>
    <StyledModalBody>{body}</StyledModalBody>
    <StyledModalFooter>{footer}</StyledModalFooter>
  </StyledModal>
);

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  alert: PropTypes.node,
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  footer: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  backdrop: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(['static'])]),
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
  headingTag: PropTypes.string,
};

Modal.defaultProps = {
  alert: null,
  header: null,
  footer: null,
  backdrop: 'static',
  size: 'md',
  headingTag: 'h1',
};

export default Modal;
