import React from 'react';
import { StyledHero, StyledHeroContent, StyledHeroTitle, StyledHeroDescription, StyledHeroLogo } from './styled';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import SubscribeForm from '../../Forms/SubscribeForm';
import LearnstabilityIcon from '../../../public/static/learnstability.svg';

const Hero = ({ title, description, image, children, subscribeForm, logo, transparentBg, left }) => {
  return (
    <StyledHero image={image} transparentBg={transparentBg}>
      {children}
      <Container>
        <Row>
          <Col xs={12}>
            <StyledHeroContent left={left}>
              {logo && (
                <Link to="/">
                  <StyledHeroLogo src={LearnstabilityIcon} alt="Learnstability" />
                </Link>
              )}
              <StyledHeroTitle left={left}>{title}</StyledHeroTitle>
              <StyledHeroDescription left={left}>{description}</StyledHeroDescription>
              {subscribeForm && <SubscribeForm />}
            </StyledHeroContent>
          </Col>
        </Row>
      </Container>
    </StyledHero>
  );
};

export default Hero;
