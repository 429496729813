import React from 'react';
import { StyledCourseOverviewCard, StyledReviewIntro, StyledCourseIntro, StyledOverviewSection } from './styled';
import ReactHtmlParser from 'react-html-parser';

const CourseOverviewCard = ({ reviewIntro, descriptionFull, commonQuestions, verdict, title }) => (
  <StyledCourseOverviewCard>
    <StyledCourseIntro>
      <h1>{title} Review</h1>
      {reviewIntro && <StyledReviewIntro>{reviewIntro}</StyledReviewIntro>}
    </StyledCourseIntro>

    {descriptionFull && (
      <StyledOverviewSection>
        <h3>Overview</h3>
        {ReactHtmlParser(descriptionFull)}
      </StyledOverviewSection>
    )}
    {verdict && (
      <StyledOverviewSection>
        <h3>Overall Verdict</h3>
        {ReactHtmlParser(verdict)}
      </StyledOverviewSection>
    )}

    {commonQuestions && (
      <StyledOverviewSection>
        <h3>Common {title} questions:</h3>
        <p>{ReactHtmlParser(commonQuestions)}</p>
      </StyledOverviewSection>
    )}
  </StyledCourseOverviewCard>
);

export default CourseOverviewCard;
