import styled from 'styled-components';

const FormError = styled.span`
  color: red;
  font-weight: 400;
  font-size: 0.8rem;
  display: flex;
  padding-top: 0.5rem;
`;

export default FormError;
