import styled from 'styled-components';
import { spacer, multiply } from '../../../helpers/style';
import Stars from '../../Stars';

export const StyledReview = styled.div`
  background: white;
  border-radius: 5px;
  /* box-shadow: 0 0 13px 0 rgba(82, 63, 105, 0.05); */
  border: solid 1px #d8d5d5;
  background-color: #fff;
  margin: ${multiply(spacer, 1)} 0;
  padding: ${multiply(spacer, 1)};
`;

export const StyledReviewAuthor = styled.div`
  margin-bottom: ${spacer};
  display: flex;
  align-items: center;
  line-height: 24px;
`;

export const StyledReviewAuthorImage = styled.div`
  fill: #c0cad8;
  width: 35px;
`;

export const StyledReviewAuthorName = styled.span`
  font-size: 0.8rem;
  padding-left: 0.8rem;
  padding-top: 0.2rem;
`;

export const StyledReviewContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledReviewContentTitle = styled.span`
  font-size: ${multiply(spacer, 1.1)};
  line-height: 24px;
  padding-top: 0.5rem;
  padding-bottom: 0.2rem;
`;

export const StyledReviewContentInfo = styled.p`
  font-size: 0.9rem;
  font-weight: 400;
`;

export const StyledReviewStars = styled(Stars)`
  span {
    font-size: 0.8rem;
  }
`;
