import React from 'react';
import { Field } from 'formik';
import { StyledStarsInput } from './styled';
import Stars from '../../Stars';
const fieldName = 'stars';

const StarsInput = () => (
  <label htmlFor={fieldName}>
    <Field name={fieldName} id={fieldName} type="number">
      {({ field: { value }, form: { setFieldValue } }) => (
        <div>
          Your rating
          <StyledStarsInput>
            <Stars count={value} editable={true} handleClick={number => setFieldValue(fieldName, number)} />
          </StyledStarsInput>
        </div>
      )}
    </Field>
  </label>
);

export default StarsInput;
