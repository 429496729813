import React, { useState, useEffect } from 'react';
import { blogMetaTags } from '../../helpers/constants';
import { Container, Row, Col } from 'reactstrap';
import Hero from '../../components/UI/Hero';
import Navbar from '../../components/Navigation';
import BlogCard from '../../components/Blog/BlogCard';
import Layout from '../../components/Layout';
import { StyledBlogWrapper } from './styled';
import { BLOG_API_URL } from '../../helpers/constants';

const Blog = ({ fetchPostsStart }) => {
  const [posts, setPosts] = useState();

  useEffect(() => {
    try {
      fetch(BLOG_API_URL)
        .then(res => res.json())
        .then(data => setPosts(data));
    } catch (error) {
      console.log('Error');
    }
  }, [fetchPostsStart]);

  const cards = posts =>
    posts.items.map((post, index) => {
      const postImageId = post.fields.heroImage.sys.id;
      const matchingImage = posts.includes.Asset.filter(asset => asset.sys.id === postImageId);
      const image = matchingImage[0].fields.file.url;
      return (
        <Col sm={12} md={4} key={index}>
          <BlogCard
            key={index}
            slug={post.fields.slug}
            imageUrl={image}
            title={post.fields.title}
            description={post.fields.description}
            date={post.publishDate}
            body={post.body}
            template="post"
          />
        </Col>
      );
    });

  return (
    <Layout metaTags={blogMetaTags}>
      <Hero title="Join now and get advanced developer articles we don't share in public." subscribeForm={true}>
        <Navbar />
      </Hero>
      <StyledBlogWrapper>
        <Container>{posts && <Row>{cards(posts)}</Row>}</Container>
      </StyledBlogWrapper>
    </Layout>
  );
};

export default Blog;

// export const pageQuery = graphql`
//   query BlogQuery {
//     allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
//       edges {
//         node {
//           title
//           description {
//             internal {
//               content
//             }
//           }
//           tags {
//             name
//           }
//           slug
//           publishDate(formatString: "MMMM Do YYYY")
//           heroImage {
//             file {
//               url
//             }
//             sizes(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
//               ...GatsbyContentfulSizes_withWebp
//             }
//           }
//           body {
//             body
//           }
//         }
//       }
//     }
//   }
// `;
