import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  body{
    font-family: 'Graphik', sans-serif;
    font-size: 1.5rem;
    line-height: 2;
    margin: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Graphik', sans-serif;
    margin: 0;
  }

  h2 {
    color: #1d2c4c;
    line-height: 32x;
    font-weight: bold;
    padding-bottom: 1.5rem;
    font-size: 1.7rem;

    @media(min-width: 480px) {
      font-size: 2.2rem;
    }

    @media(min-width: 768px) {
      font-size: 2.4rem;
    }
  }

  h3,h4,h5 {
    color: #1d2c4c;
    font-weight: 600;
  }

  p{
    font-family: 'Graphik', sans-serif;
    font-size: 16px;
    margin: 0;
    line-height: 26px;
    font-weight: 400;
    // color: #7f92a4;
    color: #3d414a;
  }

  section {
    position: relative;
  }

  ul {
    margin-top: 0;
    margin-bottom: 1rem;
  }

.call-to-action-btn a {
  text-decoration: none;
}

`;
