import React, { useEffect, Fragment } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import 'bootstrap/dist/css/bootstrap.min.css';
import './public/static/themify-icons.css';
import LoginPage from './pages/Login';
import SignupPage from './pages/Signup';
import TermsAndConditionsPage from './pages/TermsAndConditions';
import CourseSearchPage from './pages/Courses/CourseSearch';
import CourseSearchResultsPage from './pages/Courses/CourseSearchResults';
import CourseReviewPage from './pages/Courses/CourseReview';
import BlogPage from './pages/Blog';
// import DashboardPage from './pages/Dashbaord';
import SavedCoursesPage from './pages/SavedCourses';
// import AccountPage from './pages/Account';
import HomePage from './pages/Home';
import HelpPage from './pages/Help';
import RequestPasswordReset from './pages/RequestPasswordReset';
import BlogPostPage from './pages/BlogPostPage';
import NotFound from './pages/NotFound';
import './fonts.css';
import { selectCurrentUser } from './redux/user/user.selectors';
import { checkUserSession } from './redux/user/user.actions';

const App = ({ checkUserSession, currentUser }) => {
  useEffect(() => {
    checkUserSession();
  }, [checkUserSession]);

  return (
    <Fragment>
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/terms-and-conditions" component={TermsAndConditionsPage} />
        <Route exact path="/course-search" component={CourseSearchPage} />
        <Route exact path="/course-search/:course" component={CourseSearchResultsPage} />
        <Route exact path="/course/:course" component={CourseReviewPage} />
        <Route exact path="/saved-courses" component={SavedCoursesPage} />
        <Route exact path="/post/:slug" component={BlogPostPage} />
        <Route exact path="/help" component={HelpPage} />
        <Route exact path="/blog" component={BlogPage} />
        <Route exact path="/signup" render={() => (currentUser ? <Redirect to="/course-search" /> : <SignupPage />)} />
        <Route exact path="/login" render={() => (currentUser ? <Redirect to="/course-search" /> : <LoginPage />)} />
        <Route
          exact
          path="/request-reset"
          render={() => (currentUser ? <Redirect to="/course-search" /> : <RequestPasswordReset />)}
        />
        <Route path="*" component={NotFound} />
        />
      </Switch>
    </Fragment>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

const mapDispatchToProps = dispatch => ({
  checkUserSession: () => dispatch(checkUserSession()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);

// <Route exact path="/account" component={AccountPage} />;
// <Route exact path="/dashboard" render={() => (currentUser ? <DashboardPage /> : <Redirect to="/signup" />)} />;
