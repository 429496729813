import React from 'react';
import Text from '../Text';
import Link from '../Link';
import Image from '../Image';

const Logo = ({ logoWrapperStyle, logoStyle, titleStyle, withAchor, anchorProps, logoSrc, title, ...props }) => (
  <Link {...props} {...logoWrapperStyle} style={{ lineHeight: '0' }}>
    {withAchor ? (
      <a {...anchorProps}>
        {logoSrc ? <Image src={logoSrc} alt={title} {...logoStyle} /> : <Text content={title} {...titleStyle} />}
      </a>
    ) : (
      <>{logoSrc ? <Image src={logoSrc} alt={title} {...logoStyle} /> : <Text content={title} {...titleStyle} />}</>
    )}
  </Link>
);

export default Logo;
