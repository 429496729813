import styled from 'styled-components';

export const StyledIcon = styled.div`
  color: ${props => (props.color ? props.color : 'white')};
  background-color: ${props => (props.backgroundColor ? props.backgroundColor : 'white')};
  font-size: 22px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 0.25rem;
`;
