import React from 'react';
import { SpinnerStyles } from './styled';
import SpinnerGif from '../../../public/static/image/icons/loading.svg';

const Spinner = () => (
  <SpinnerStyles>
    <img src={SpinnerGif} alt="Spinner" />
  </SpinnerStyles>
);

export default Spinner;
