import React from 'react';
import {
  StyledReview,
  StyledReviewAuthor,
  StyledReviewAuthorImage,
  StyledReviewAuthorName,
  StyledReviewContent,
  StyledReviewContentTitle,
  StyledReviewContentInfo,
  StyledReviewStars,
} from './styled';
// import authorImage from '../../../public/static/image/author.jpg';
import { ReactComponent as AvatarImage } from '../../../public/static/image/avatar.svg';

const CourseReviewCard = ({ title, content, rating, reviewerName }) => (
  <StyledReview>
    <StyledReviewAuthor>
      <StyledReviewAuthorImage>
        <AvatarImage />
      </StyledReviewAuthorImage>
      <StyledReviewAuthorName>{reviewerName}</StyledReviewAuthorName>
    </StyledReviewAuthor>
    <StyledReviewContent>
      <StyledReviewStars count={rating} />
      <StyledReviewContentTitle>{title}</StyledReviewContentTitle>
      <StyledReviewContentInfo>{content}</StyledReviewContentInfo>
    </StyledReviewContent>
  </StyledReview>
);

export default CourseReviewCard;
