import React, { useEffect } from 'react';
import { StyledMenu, StyledUserMenu } from './styled';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { signOutStart } from '../../../redux/user/user.actions';

const Menu = ({ open, currentUser, setOpen, history, signOutStart }) => {
  useEffect(() => {
    history.listen((location, action) => {
      setOpen(false);
    });
  });

  return (
    <StyledMenu open={open}>
      {currentUser && (
        <StyledUserMenu>
          <Link to="/wish-list">Saved</Link>
          <Link to="/course-search">Search Courses</Link>
          <Link onClick={signOutStart}>Logout</Link>
        </StyledUserMenu>
      )}

      {!currentUser && (
        <StyledUserMenu>
          <Link to="/course-search">Search Courses</Link>
          <Link to="/signup">Sign Up</Link>
          <Link to="/login">Log In</Link>
        </StyledUserMenu>
      )}
    </StyledMenu>
  );
};

const mapDispatchToProps = dispatch => ({
  signOutStart: () => dispatch(signOutStart()),
});

export default connect(null, mapDispatchToProps)(withRouter(Menu));
