import styled from 'styled-components';
import { multiply, spacer } from '../../../helpers/style';
import hero1 from '../../../public/static/image/heros/g7.jpg';

export const StyledCourseSearch = styled.section`
  h2 {
    font-weight: 600;
    color: white;
    font-size: 1.8rem;
    text-shadow: 0 0 12px #212224;

    @media (min-width: 480px) {
      padding-bottom: ${multiply(spacer, 2.5)};
      font-size: 2.8rem;
      width: 50%;
    }
  }
`;

export const StyledSearchHero = styled.div`
  background: url(${hero1}) top center no-repeat;
  background-size: cover;
  position: relative;
  background-position-y: 55%;
`;

export const StyledSearchHeroContent = styled.div`
  padding: 14rem 0 3rem 0;
  @media (min-width: 480px) {
    padding: ${multiply(spacer, 12)} 0 ${multiply(spacer, 14)} 0;
  }
`;

export const StyledCategoriesWrapper = styled.section`
  padding: ${multiply(spacer, 3)} 0;
  @media (min-width: 480px) {
    padding: ${multiply(spacer, 5)} 0;
  }
`;

export const StyledCategoriesSection = styled.div`
  &:not(:last-child) {
    padding-bottom: 3rem;
  }
`;

export const StyledCategoriesHeading = styled.h3`
  padding-bottom: ${multiply(spacer, 1.5)};
`;
