import styled from 'styled-components';

export const StyledSalesIntro = styled.section`
  h2 {
    text-align: center;
    font-weight: bold;
  }
`;
export const StyledSalesIntroParagraph = styled.p`
  text-align: center;
  font-weight: 400;
`;

export const SalesIntroBlock = styled.div`
  text-align: left;
  z-index: 0;
  position: relative;
  background: #ffffff;
  box-shadow: 0 0 0 1px #ebebeb;
  box-sizing: border-box;
  padding: 3rem;
  box-shadow: 0px 3px 10px 0px rgba(38, 59, 94, 0.1);
  margin: 0px 20px 0px 0px;
  padding: 30px 40px 30px 40px;
  p {
    font-weight: 400;
    line-height: 20px;
  }
  &:hover {
    transition: box-shadow 0.1s ease, transform 0.1s ease, z-index 0s 0.1s ease, -webkit-transform 0.3s ease;
    z-index: 1;
    box-shadow: 0 8px 50px rgba(0, 0, 0, 0.2);
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
    border-radius: 4px;
  }
`;

export const SalesIntroContent = styled.div`
  padding-top: 1rem;
`;

export const StyledSalesHeading = styled.div`
  padding: 6rem 0 4rem 0;
`;

export const StyledSalesText = styled.div`
  padding-bottom: 5rem;
`;
