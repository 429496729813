import React from 'react';
import { StyledSocial } from './styled';

const Social = ({ title }) => {
  const shareUrl = 'https://www.learnstability.com';
  const socialMessage = `Check out detailed course reviews of ${title} on Leanstability.com `;
  const twitterTitle = `Check out detailed course reviews of ${title} on https://www.leanstability.com #learnstaability #webdev #coursereview`;

  return (
    <StyledSocial>
      <ul>
        <li>
          <a
            href={`https://twitter.com/intent/tweet?text=${twitterTitle}&amp;url=${shareUrl}&amp;via=Learnstability`}
            title="Share this article on Twitter"
            onClick="window.open(this.href,'targetWindow','toolbar=no,location=0,status=no,menubar=no,scrollbars=yes,resizable=yes,width=600,height=250'); return false;"
          >
            <i class="ti-twitter-alt"></i>
          </a>
        </li>
        <li class="icon icon--facebook">
          <a
            href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}amp;title=${socialMessage}`}
            title="Share this article on Facebook"
            onClick="window.open(this.href,'targetWindow','toolbar=no,location=0,status=no,menubar=no,scrollbars=yes,resizable=yes,width=600,height=250'); return false;"
          >
            <i class="ti-facebook"></i>
          </a>
        </li>
        <li class="icon icon--buffer">
          <a
            href={`https://www.linkedin.com/shareArticle?mini=true&url=${shareUrl}&title=${socialMessage}&source=LinkedIn`}
            title="Share this article on linkedin"
            onClick="window.open(this.href,'targetWindow','toolbar=no,location=0,status=no,menubar=no,scrollbars=yes,resizable=yes,width=750,height=575'); window.convertkit.events.sharePost('Buffer'); return false;"
          >
            <i class="ti-linkedin"></i>
          </a>
        </li>
        <li class="icon icon--email">
          <a
            href={`mailto:?subject=${socialMessage}&amp;body=${shareUrl}`}
            title="Send this article to a friend!"
            onClick="window.convertkit.events.sharePost('Email');"
          >
            <i class="ti-email"></i>
          </a>
        </li>
      </ul>
    </StyledSocial>
  );
};

export default Social;
