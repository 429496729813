import React, { useState, useEffect } from 'react';
import Stars from '../../Stars';
import { StyledCourseReviewData, StyledCourseReviewChart, StlyedCourseReviewScore, StyedScoreValue } from './styled';

const CourseChartCard = ({ calculateReviewData, courseReviews }) => {
  const [sampleData, setSampleData] = useState([]);

  useEffect(() => {
    const getTotalReviews = reviewScore => {
      if (reviewScore) {
        const reviewNumbers = courseReviews.map(review => review.rating);
        const total = reviewNumbers.filter(x => x === reviewScore).length;
        return total;
      }
      return 0;
    };

    const total5Stars = getTotalReviews(5);
    const total4Stars = getTotalReviews(4);
    const total3Stars = getTotalReviews(3);
    const total2Stars = getTotalReviews(2);
    const total1Stars = getTotalReviews(1);

    setSampleData([
      {
        name: 'Excellent',
        value: total5Stars ? 100 : 0,
        total: total5Stars,
      },
      {
        name: 'Very Good',
        value: total4Stars ? 70 : 0,
        total: total4Stars,
      },
      {
        name: 'Average',
        value: total3Stars ? 60 : 0,
        total: total3Stars,
      },
      {
        name: 'Poor',
        value: total2Stars ? 50 : 0,
        total: total2Stars,
      },
      {
        name: 'Terrible',
        value: total1Stars ? 30 : 0,
        total: total1Stars,
      },
    ]);
  }, [courseReviews]);

  return (
    <StyledCourseReviewData>
      <StyledCourseReviewChart reviewPercentages={sampleData} />
      <StlyedCourseReviewScore>
        <StyedScoreValue>{calculateReviewData}</StyedScoreValue>
        <Stars count={calculateReviewData} />
      </StlyedCourseReviewScore>
    </StyledCourseReviewData>
  );
};

export default CourseChartCard;
