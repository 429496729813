import React, { Fragment, useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import Layout from '../../components/Layout';
import Hero from '../../components/UI/Hero';
import Nav from '../../components/Navigation';
import CallToAction from '../../components/CallToAction';
import { StyledBlogPost, StyledBlogSection } from './styled';
import { Container, Row, Col } from 'reactstrap';
import { BLOG_API_URL } from '../../helpers/constants';

const PostPage = () => {
  const [post, setPost] = useState();

  useEffect(() => {
    const postUrl = window.location.pathname.split('/').pop();
    try {
      fetch(BLOG_API_URL)
        .then(res => res.json())
        .then(data => setPost(data.items.filter(match => match.fields.slug === postUrl)));
    } catch (error) {
      console.log(error);
    }
  }, []);

  const postMetaTags = {
    description: post ? `${post[0].fields.description}` : '',
    image: post ? `${post[0].fields.title}` : '',
    title: post ? `${post[0].fields.title}` : '',
    type: `article`,
  };

  console.log(post);
  return (
    <Fragment>
      {post && (
        <Layout metaTags={postMetaTags}>
          <Hero
            title={post[0].fields.title}
            description={post[0].fields.description}
            image={window.sessionStorage.getItem('courseImage')}
            transparentBg={true}
          >
            <Nav />
          </Hero>
          <Row>
            <Col xs={12}>
              <StyledBlogPost>
                <StyledBlogSection>
                  <Container>{post && <ReactMarkdown className="markdown" source={post[0].fields.body} />}</Container>
                </StyledBlogSection>
              </StyledBlogPost>
            </Col>
          </Row>

          <CallToAction />
        </Layout>
      )}
    </Fragment>
  );
};

export default PostPage;

// <SocialFixed />
//    <ReactMarkdown className="markdown" source={'test'} />;

//   <CallToAction />;
