import styled from 'styled-components';
import Button from '../../UI/Button';

export const StyledSalesImageBlock = styled.section`
  img {
    width: 90%;
    margin-bottom: 1rem;
    @media (min-width: 480px) {
      width: 100%;
      margin-bottom: 0;
    }
  }
`;

export const SalesImageContent = styled.div`
  h3 {
    padding-bottom: 1.5rem;
    color: #1d2c4c;
    line-height: 40px;
    font-weight: bold;
    font-size: 2rem;
  }
  p {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  padding-bottom: 2rem;

  @media (min-width: 480px) {
    padding-bottom: 0;
  }
`;

export const SalesImageButton = styled(Button)`
  padding: 0.8rem 1.5rem;
  margin-top: 2rem;
  width: 150px;
  font-size: 0.9rem;
`;
