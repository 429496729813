import styled from 'styled-components';
import pooch from '../../public/static/image/heros/pooch.jpeg';

export const StyledHero = styled.div`
  background: url(${pooch}) top center no-repeat;
  background-size: cover;
  position: relative;
  height: 100vh;
`;

export const StyledHeroContent = styled.section`
  h2 {
    padding-bottom: 2.5rem;
    font-weight: 600;
    color: white;
    font-size: 1.8rem;
    width: 50%;
    @media (min-width: 480px) {
      font-size: 4.5rem;
    }
  }
  p {
    color: white;
    font-size: 1rem;
    color: white;
    line-height: 35px;
    font-weight: 400;
    @media (min-width: 480px) {
      font-size: 1.4rem;
      width: 50%;
    }
  }

  p:last-of-type {
    padding-bottom: 2rem;
  }

  padding-bottom: 1rem;
`;

export const StyledContent = styled.div`
  padding: 8rem 0;
  @media (min-width: 480px) {
    padding: 14rem 0;
  }
`;
