import React from 'react';
import { Link } from 'react-router-dom';
import { StyledAccountIcon, StyledDropdownToggle, StyledDropdownMenu, StyledDropdownItem } from './styled';
import { UncontrolledDropdown } from 'reactstrap';
import { ReactComponent as AccountIcon } from '../../../public/static/image/avatar.svg';

const NavAccountDropdown = ({ handleSignOut, userName, background }) => (
  <UncontrolledDropdown>
    <StyledDropdownToggle>
      <StyledAccountIcon background={background}>
        <AccountIcon />
      </StyledAccountIcon>
    </StyledDropdownToggle>
    <StyledDropdownMenu>
      <Link to="/wish-list" style={{ textDecoration: 'none' }}>
        <StyledDropdownItem>Saved Courses</StyledDropdownItem>
      </Link>

      <StyledDropdownItem onClick={handleSignOut}>Log Out</StyledDropdownItem>
    </StyledDropdownMenu>
  </UncontrolledDropdown>
);

export default NavAccountDropdown;

//  <StyledAccountName background={background}>{userName}</StyledAccountName>;

// <Link to="/dashboard" style={{ textDecoration: 'none' }}>
//   <StyledDropdownItem>Dashboard</StyledDropdownItem>
// </Link>;

// <Link to="/account" style={{ textDecoration: 'none' }}>
//   <StyledDropdownItem>Account</StyledDropdownItem>
// </Link>;
