import React from 'react';
import SignupForm from '../../components/Forms/SignupForm';
import Layout from '../../components/Layout';
import { signupMetaTags } from '../../helpers/constants';
import learnstabilityLogo from '../../public/static/image/ls6.svg';
import {
  StyledLogin,
  StyledLeftContent,
  StyledLoginTitle,
  StyledLoginLeft,
  StyledLogoWrapper,
  StyledLogo,
  StyledLoginRight,
} from '../Login/styled';

const SignupPage = () => {
  return (
    <Layout metaTags={signupMetaTags}>
      <StyledLogin>
        <StyledLoginLeft>
          <StyledLeftContent>
            <StyledLogoWrapper>
              <StyledLogo src={learnstabilityLogo} alt="Learnstability Logo" />
            </StyledLogoWrapper>
            <StyledLoginTitle>Sign up to get started.</StyledLoginTitle>
            <SignupForm />
          </StyledLeftContent>
        </StyledLoginLeft>
        <StyledLoginRight />
      </StyledLogin>
    </Layout>
  );
};

export default SignupPage;
