import React, { useState } from 'react';
import {
  StyledSubscrbibeForm,
  StyledSubscribeGroup,
  StyledSubscribebutton,
  StyledSubscribeAlert,
} from '../../styles/Form';
import { Formik, Form, Field } from 'formik';
import jsonp from 'jsonp';
import queryString from 'query-string';

const SubscribeForm = () => {
  const [success, setSuccess] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState();

  const initialValues = {
    EMAIL: '',
  };

  const onSubmit = (values, { resetForm }) => {
    setLoading(true);
    setError(null);
    setSuccess(null);
    jsonp(
      `https://gmail.us4.list-manage.com/subscribe/post-json?u=6828f632a151d288112e60825&amp;id=c7931ba87e&${queryString.stringify(
        values,
      )}`,
      { param: 'c' },
      (err, data) => {
        setLoading(false);
        resetForm({});
        if (data.result === 'error') {
          setError(data.msg);
        } else {
          setSuccess(data.msg);
        }
      },
    );
  };

  return (
    <StyledSubscrbibeForm>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ isSubmitting, handleChange }) => (
          <Form id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form">
            <div>
              <StyledSubscribeGroup>
                <label htmlFor="mce-EMAIL" style={{ display: 'none' }}>
                  Email Address{' '}
                </label>
                <Field
                  type="email"
                  name="EMAIL"
                  id="mce-EMAIL"
                  placeholder="Your email address"
                  onChange={handleChange}
                />

                <div id="mce-responses" className="clear">
                  <div className="response" id="mce-error-response" style={{ display: 'none' }}></div>
                  <div className="response" id="mce-success-response" style={{ display: 'none' }}></div>
                </div>
                <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
                  <input type="text" name="b_6828f632a151d288112e60825_c7931ba87e" tabIndex="-1" value="" readOnly />
                </div>

                <StyledSubscribebutton size="lg" type="submit" loading={loading}>
                  Sign Up
                </StyledSubscribebutton>
              </StyledSubscribeGroup>
              {error && <StyledSubscribeAlert>{error}</StyledSubscribeAlert>}
              {success && <StyledSubscribeAlert>{success}</StyledSubscribeAlert>}
            </div>
          </Form>
        )}
      </Formik>
    </StyledSubscrbibeForm>
  );
};

export default SubscribeForm;
