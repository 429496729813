import CourseActionTypes from './course.types';

export const fetchCoursesStart = (searchTerm, limitIncrease) => ({
  type: CourseActionTypes.FETCH_COURSES_START,
  term: searchTerm,
  limitIncrease: limitIncrease,
});

export const fetchCoursesSuccess = courses => ({
  type: CourseActionTypes.FETCH_COURSES_SUCCESS,
  payload: courses,
});

export const fetchCoursesFailure = error => ({
  type: CourseActionTypes.FETCH_COURSES_FAILURE,
  payload: error,
});
