import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Row, Col, Container } from 'reactstrap';
import { wishListMetaTags } from '../../helpers/constants';
import { StyledWishListHeader, StyledWishListContent } from './styled';
import { selectCurrentUser } from '../../redux/user/user.selectors';
import { selectAllCourses } from '../../redux/courses/course.selectors';
import { removeLikedCourse } from '../../firebase/firebase.utils';
import CourseCard from '../../components/Course/CourseCard';
import Navbar from '../../components/Navigation';
import Layout from '../../components/Layout';

const SavedCourses = ({ currentUser, courses }) => {
  const [savedCourses, setSavedCourses] = useState();
  const [savedCoursesIds, setSavedCoursesIds] = useState();

  useEffect(() => {
    if (currentUser) {
      const matchingCourses = courses.filter(({ id }) => currentUser.savedCourses.includes(id));
      const matchingCoursesIds = courses.map(course => course.id);
      setSavedCourses(matchingCourses);
      setSavedCoursesIds(matchingCoursesIds);
    }
  }, [currentUser, courses]);

  const toggleCourseWishList = (e, courseId) => {
    e.preventDefault();
    e.stopPropagation();

    if (savedCoursesIds.includes(courseId)) {
      removeLikedCourse(currentUser.id, courseId);
    }
  };

  const renderCourses = (courses, savedCoursesIds) =>
    courses.map((course, index) => (
      <Col xs={6} sm={4} key={index}>
        <CourseCard
          currentCourse={course}
          tags="5"
          rating="5"
          likedCourses={savedCoursesIds}
          toggleCourseWishList={toggleCourseWishList}
          hover
          bordershadow
        />
      </Col>
    ));

  return (
    <Layout metaTags={wishListMetaTags}>
      <Navbar />
      <StyledWishListHeader>
        <Container>
          <Row>
            <Col xs={12}>
              <h2>Saved Courses</h2>
            </Col>
          </Row>
        </Container>
      </StyledWishListHeader>

      <StyledWishListContent>
        <Container>
          <Row>{savedCourses && renderCourses(savedCourses, savedCoursesIds)}</Row>
        </Container>
      </StyledWishListContent>
    </Layout>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  courses: selectAllCourses,
});

export default connect(mapStateToProps)(SavedCourses);
