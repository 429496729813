import React from 'react';
import Navbar from '../../components/Navigation';
// import Footer from '../components/Footer';
import SalesImageBlock from '../../components/Sales/SalesImageBlock';
import SalesIntro from '../../components/Sales/SalesIntro';
import Benefits from '../../components/Benefits';
import Layout from '../../components/Layout';
import { LightBackground } from '../../components/styles/BackgroundStyles';
import { Container, Col, Row } from 'reactstrap';
import {
  HomeDarkBackground,
  HomeLightBackground,
  StyledHomeHero,
  StyledHomeHeroContent,
  StyledHomeHeroWrap,
  StyledHomeHeroShape,
  StyledHomeHeroContentText,
} from './styled';
import { defaultMetaTags } from '../../helpers/constants';
import BannerBg from '../../public/static/image/heros/heroSeperator.svg';
import SearchForm from '../../components/Forms/SearchForm';
import reviewSalesImage from '../../public/static/image/blogging.svg';
import teachingSalesImage from '../../public/static/image/teaching.svg';

const Home = () => {
  return (
    <Layout metaTags={defaultMetaTags}>
      <StyledHomeHero>
        <Navbar background />

        <StyledHomeHeroContent>
          <Container>
            <Row>
              <Col xs={12}>
                <StyledHomeHeroWrap>
                  <StyledHomeHeroContentText>
                    <h2>Find Your Next Online Course Today</h2>
                    <p>
                      Learnstability is everything you need to make an informed descision when searchng for your next
                      online course.
                    </p>
                  </StyledHomeHeroContentText>

                  <SearchForm redirect />
                </StyledHomeHeroWrap>
              </Col>
            </Row>
          </Container>
          <StyledHomeHeroShape src={BannerBg} alt="Shape" />
        </StyledHomeHeroContent>
      </StyledHomeHero>
      <LightBackground>
        <SalesIntro />
      </LightBackground>
      <HomeDarkBackground>
        <SalesImageBlock
          position="right"
          button={true}
          imageAlt="Happy Man Orange Shirt"
          buttonUrl="/course-search"
          img={reviewSalesImage}
        >
          <h2>Online course reviews all in one place.</h2>
          <p>
            Leaarnstability provides accurate online course reviews backed by real developers. Whether you’re looking
            to purchase a course, evaluate a subscription or just see what courses are avaialbe, Learnstability can
            help you get the most out of purchasing an online course.
          </p>

          <p>
            We review courses from major platforms such as Udemy, Pluralsight, Team Treehouse, Coursera and many more.
          </p>
        </SalesImageBlock>
      </HomeDarkBackground>
      <HomeLightBackground>
        <SalesImageBlock imageAlt="Web Development Tech" img={teachingSalesImage}>
          <h2>Saving you money when learning online.</h2>
          <p>
            When looking to learn a new topic for example Web Development you are immediately overwhelmed by the sheer
            ammount of videos, tutorials and guides online.
          </p>

          <p>
            Learnstability is a platform to allow you to filter through the mess and find great courses to level up
            your online learning.
          </p>
        </SalesImageBlock>
      </HomeLightBackground>
      <HomeDarkBackground>
        <Container>
          <div className="row justify-content-center">
            <div className="col-md-8 col-lg-7">
              <div className="section-heading text-center mb-5">
                <h2>We are creating a better way for aspiring developers to learn online</h2>
                <p>
                  Our reviews are designed to give you the full learning breakdown. Including what you get from a
                  course, Feature length videos by the best authors on the web to top articles, posts and online
                  documentation.
                </p>
              </div>
            </div>
          </div>
          <Benefits />
        </Container>
      </HomeDarkBackground>
    </Layout>
  );
};

export default Home;
