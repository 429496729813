import styled from 'styled-components';
import { DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';

export const StyledDropdownToggle = styled(DropdownToggle)`
  background: white;
  color: black;
  padding: 0.5rem 1rem;
  font-size: 0.6rem;
  border: 0;
  display: flex;
  align-items: center;
  border: 1px solid #e5e5e5;

  &:hover,
  &:active,
  &:focus {
    outline: 0;
    box-shadow: none;
    background: transparent;
    color: black;
  }
`;

export const StyledDropdownItem = styled(DropdownItem)`
  font-size: 0.9rem;
  line-height: 36px;
  display: block;
  padding: 0.25rem 1rem;
  color: #525f7f;

  &:hover {
    background-color: rgba(14, 30, 37, 0.03);
    text-decoration: none;
  }

  a {
    color: inherit;
    text-decoration: none;
    white-space: nowrap;
  }
`;

export const StyledDropdownMenu = styled(DropdownMenu)`
  &.dropdown-menu {
    padding-top: 0;
    padding-bottom: 0;
  }
`;
