import styled from 'styled-components';

export const StyledWishListHeader = styled.div`
  background: #1f3e54;
  padding: 5rem 0;
  h2 {
    padding: 0;
    color: white;
  }
`;

export const StyledWishListContent = styled.section`
  height: 100vh;
  background: #f8f8f8;
  padding-top: 2rem;
`;
