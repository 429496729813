import ReviewActionTypes from './review.types';

const INITIAL_STATE = {
  reviews: null,
  error: null,
  isFetching: false,
};

const reviewReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ReviewActionTypes.FETCH_REVIEWS_START:
      return {
        ...state,
        isFetching: true,
      };
    case ReviewActionTypes.FETCH_REVIEWS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        reviews: action.payload,
      };
    case ReviewActionTypes.FETCH_REVIEWS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reviewReducer;
