import React from 'react';
import Button from '../UI/Button';
import { Link } from 'react-router-dom';
import { StyledCallToAction } from './styled';
import { Container } from 'reactstrap';

const CallToAction = () => {
  return (
    <StyledCallToAction>
      <Container>
        <div className="row justify-content-center">
          <div className="col-11 mb-3">
            <div className="call-to-action-content text-center text-white">
              <h2 className="text-white">Compare the best online courses.</h2>
              <p>Search online through Learnstability and discover the top online courses.</p>
            </div>
          </div>
          <div className="col-11">
            <div className="call-to-action-btn text-center">
              <Link to="/course-search">
                <Button size="lg">Search Courses</Button>
              </Link>
            </div>
          </div>
        </div>
      </Container>
    </StyledCallToAction>
  );
};

export default CallToAction;
