import React, { useState } from 'react';
import * as Yup from 'yup';
import * as firebase from 'firebase/app';
import Error from '../../Error';
import FormError from '../../styles/FormError';
import Button from '../../../components/UI/Button';
import { Link } from 'react-router-dom';
import { Formik, Field } from 'formik';
import { connect } from 'react-redux';
import { googleSignInStart, emailSignInStart } from '../../../redux/user/user.actions';
import { RequestPasswordResetForm } from './styled';

const PasswordResetForm = ({ setEmailSent }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const initialValues = {
    email: '',
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email()
      .required(),
  });

  const onSubmit = async ({ email }) => {
    sendPasswordResetEmail(email);
  };

  const sendPasswordResetEmail = email => {
    setLoading(true);
    const auth = firebase.auth();

    auth
      .sendPasswordResetEmail(email)
      .then(() => {
        setEmailSent(true);
        setLoading(false);
      })
      .catch(() => {
        setError(true);
      });
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      {({ errors, touched, handleChange }) => (
        <RequestPasswordResetForm width="500px" shadow={false}>
          <fieldset>
            <Error error={error} />
            <label htmlFor="email">
              Email
              <Field type="text" name="email" placeholder="yourname@example.com" onChange={handleChange} />
              {errors.email && touched.email ? <FormError>{errors.email}</FormError> : null}
            </label>
            <Button disabled={loading} loading={loading} type="submit">
              Request Password Reset
            </Button>
          </fieldset>

          <div className="account-actions">
            <div className="new-account">
              <span>Remember your details? </span>
              <Link to="/login">Log In</Link>
            </div>
          </div>
        </RequestPasswordResetForm>
      )}
    </Formik>
  );
};

const mapDispatchToProps = dispatch => ({
  googleSignInStart: () => dispatch(googleSignInStart()),
  emailSignInStart: (email, password) => dispatch(emailSignInStart({ email, password })),
});

export default connect(mapDispatchToProps)(PasswordResetForm);
