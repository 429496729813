import React from 'react';
import { StyledCourseReviewChartContainer, StyledReviewSummary, StyledModal, StyledModalHeader } from './styled';
import Button from '../../UI/Button';
import CourseChartCard from '../CourseChartCard';

const CourseChartDataCard = ({
  currentUser,
  toggleWriteReview,
  modalOpen,
  currentCourse,
  calculateReviewData,
  courseReviews,
  renderModalBody,
}) => {
  return (
    <StyledCourseReviewChartContainer>
      <StyledReviewSummary>
        <h3>Community Reviews</h3>

        {!currentUser ? (
          <Button href={'/signup'}>Sign up to review</Button>
        ) : (
          <Button onClick={toggleWriteReview}>Write a review</Button>
        )}

        {modalOpen && (
          <StyledModal
            header={<StyledModalHeader>Post a review</StyledModalHeader>}
            body={renderModalBody()}
            toggle={toggleWriteReview}
            isOpen={modalOpen}
          />
        )}
      </StyledReviewSummary>

      <CourseChartCard
        calculateReviewData={
          currentCourse.reviewScores && calculateReviewData(Object.values(currentCourse.reviewScores))
        }
        courseReviews={courseReviews}
      />
    </StyledCourseReviewChartContainer>
  );
};

export default CourseChartDataCard;
