import { takeLatest, call, put, all } from 'redux-saga/effects';
import { fetchPostsSuccess, fetchPostsFailure } from './blog.actions';
import BlogActionTypes from './blog.types';

// xport function * loginFlow(action) {
//   try {
//     const {username, password} = action.payload;
//     const responsePromise = yield call(login, {username, password, isRegistering: false});
//     console.log('loginFlow responsePromise result', result);
//     yield put(creators.queueLoginSucceededAction());
//     yield put(push('/'));
//   }
//   catch(err) {
//     console.log('loginFlow responsePromise err', err);
//     yield put(creators.queueLoginFailedAction());
//   }
// }

export function* fetchPostsAsync() {
  console.log('HIT');

  const API_URL = `https://cdn.contentful.com/spaces/2am4gksp2vil/entries?access_token=bnGpC3pmm59dS5EFNXxqScwcqYZ9ziZwQMU6Ko460MA&content_type=blogPost`;

  try {
    const value = fetch(API_URL)
      .then(res => res.json())
      .then(data => data.items);

    if (value) {
      yield call(fetchPostsSuccess(value));
    }
  } catch (error) {
    yield put(fetchPostsFailure(error.message));
  }
}

export function* fetchPostsStart() {
  yield takeLatest(BlogActionTypes.FETCH_POSTS_START, fetchPostsAsync);
}

export function* blogSagas() {
  yield all([call(fetchPostsStart)]);
}
