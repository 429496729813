import styled from 'styled-components';
import CourseReviewChart from '../../../components/Course/CourseReviewChart';

export const StyledCourseReviewData = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
  flex-wrap: wrap-reverse;
  line-height: 24px;
  @media (min-width: 480px) {
    flex-wrap: nowrap;
  }
`;

export const StlyedCourseReviewScore = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 2.5rem;
  padding-bottom: 1rem;
  background: white;

  @media (min-width: 480px) {
    flex-direction: column;
    padding-bottom: 0;
  }
`;

export const StyedScoreValue = styled.span`
  padding-right: 1rem;

  @media (min-width: 480px) {
    padding-right: 0rem;
  }
`;

export const StyledCourseReviewChart = styled(CourseReviewChart)`
  margin-top: 1rem;
`;
