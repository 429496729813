import { readableColor, transparentize } from 'polished';
import { STATUS_CONFIRMED, STATUS_CANCELLED, STATUS_ACTIVE, STATUS_COMPLETED } from '../constants';

export const gray = colorName => ({ theme }) => theme.grays[colorName];
export const color = colorName => ({ theme }) => theme.colors[colorName];
export const themeColor = colorName => ({ theme }) => theme.themeColors[colorName];

export const colorYIQ = backgroundColor => ({
  theme: {
    themeColors: { light, dark },
  },
}) => (readableColor(backgroundColor) === '#000' ? dark : light);

export const transparentizeColor = (amount, colorValue) => ({ theme }) => {
  const parsedColor = typeof colorValue === 'function' ? colorValue({ theme }) : colorValue;
  const parsedAmount = typeof amount === 'function' ? amount({ theme }) : amount;
  return transparentize(parsedAmount, parsedColor);
};

export const statusColor = status => ({ theme }) => {
  const { success, danger, warning, completed } = theme.themeColors;
  const colors = {
    [STATUS_ACTIVE]: success,
    [STATUS_CANCELLED]: danger,
    [STATUS_COMPLETED]: completed,
    [STATUS_CONFIRMED]: warning,
  };

  return colors[status];
};
