// Primary Colors
const blueRise = '#1F3E54';
const catchams = '#194971';
const blueSea = '#1c85e8';
const matisse = '#2268A2';
const mariner = '#3184C9';
const danube = '#64A1D8';
const sail = '#AAD4F6';

// Red Colors
const punch = '#DC2F30';
const sunglo = '#E46564';
const wewak = '#F6AAAA';

// Green Colors
const elephant = '#134544';
const greenpea = '#1B655F';
const loch = '#299287';
const keppel = '#3CAFA3';
const viking = '#6DD8D3';
const blizzard = '#A7EEEB';
const persianGreen = '#00a082';

// Grey Colors
const darkshine = '#0e1318'; // Text
const alabaster = '#202A34 ';
const wildSand = '#404B5A';
const slate = '#42526E'; // Sub text
const catskillWhite = '#6E7A8A';
const mercury = '#929FB0';
const ghost = '#AFBECD';
const rollingStone = '#CCD4DB';
const mineShaft = '#D5DDE5';
const cadetBlue = '#acb5bf';
const dustyGray = '#E1E7EC';
const soapstone = '#f8f8f8';

export const grays = {
  100: alabaster,
  200: wildSand,
  300: catskillWhite,
  400: mercury,
  500: ghost,
  600: rollingStone,
  700: mineShaft,
};

export const colors = {
  blueRise,
  catchams,
  matisse,
  mariner,
  danube,
  sail,
  white: '#fff',
  black: '#000',
  gray: catskillWhite,
  grayDark: mercury,
  dustyGray,
  soapstone,
  punch,
  sunglo,
  wewak,
  elephant,
  greenpea,
  loch,
  keppel,
  ghost,
  viking,
  blizzard,
  alabaster,
  wildSand,
  catskillWhite,
  mineShaft,
  mercury,
  darkshine,
  blueSea,
  slate,
};

export const themeColors = {
  primary: blueRise,
  secondary: matisse,
  success: persianGreen,
  info: mariner,
  warning: sunglo,
  danger: punch,
  light: wildSand,
  dark: mineShaft,
  completed: cadetBlue,
};
