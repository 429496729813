import BlogActionTypes from './blog.types';

export const fetchPostsStart = () => ({
  type: BlogActionTypes.FETCH_POSTS_START,
});

export const fetchPostsSuccess = posts => ({
  type: BlogActionTypes.FETCH_POSTS_SUCCESS,
  payload: posts,
});

export const fetchPostsFailure = error => ({
  type: BlogActionTypes.FETCH_POSTS_FAILURE,
  payload: error,
});
