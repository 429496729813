import React from 'react';
import Error from '../../Error';
import { Link } from 'react-router-dom';
import Button from '../../UI/Button';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import { connect } from 'react-redux';
import { signUpStart } from '../../../redux/user/user.actions';
import { StyledSignUpForm, StyledButtonBlock } from './styled';

const SignupForm = ({ error, signUpStart, isLoading }) => {
  const initialValues = {
    displayName: '',
    email: '',
    password: '',
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email()
      .required(),
    displayName: Yup.string().required(),
    password: Yup.string()
      .min(8)
      .max(16)
      .required(),
  });

  const onSubmit = async ({ displayName, email, password }) => {
    signUpStart({ displayName, email, password });
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ errors, touched, handleChange }) => (
        <StyledSignUpForm width="500px">
          <fieldset aria-busy={isLoading}>
            <Error error={error} />
            <label htmlFor="displayName">
              Name
              <Field type="text" name="displayName" placeholder="Tom Smith" onChange={handleChange} />
            </label>
            <label htmlFor="email">
              Email
              <Field type="text" name="email" placeholder="e.g tomsmith@personalemail.com" onChange={handleChange} />
            </label>
            <label htmlFor="password">
              Password
              <Field type="password" name="password" placeholder="Enter a secure password" onChange={handleChange} />
            </label>
            <StyledButtonBlock>
              <Button disabled={isLoading} loading={isLoading} type="submit">
                Sign Up
              </Button>
            </StyledButtonBlock>
          </fieldset>

          <div className="account-actions">
            <div className="new-account">
              <span>Already have an account? </span>
              <Link to="/login">Sign in</Link>
            </div>
          </div>
        </StyledSignUpForm>
      )}
    </Formik>
  );
};

const mapStateToProps = state => ({
  isLoading: state.user.isLoading,
  error: state.user.error,
});

const mapDispatchToProps = dispatch => ({
  signUpStart: userCredentials => dispatch(signUpStart(userCredentials)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignupForm);
