import styled from 'styled-components';
import FormWrapper from '../../styles/Form';

export const StyledSignUpForm = styled(FormWrapper)`
  width: 100%;
  @media (min-width: 480px) {
    min-width: 450px;
  }

  label {
    margin-bottom: 1rem;
    @media (min-width: 480px) {
      margin-bottom: 1.8rem;
    }
  }

  .new-account {
    span {
      font-weight: 400;
    }
  }

  .account-actions {
    padding-top: 0;
  }
`;

export const StyledButtonBlock = styled.div`
  button:first-child {
    margin-bottom: 1rem;
    margin-top: 2rem;
  }
`;
