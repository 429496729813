import styled from 'styled-components';

export const SpinnerStyles = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &svg {
    color: rgb(255, 230, 0);
  }
  img {
    width: 40px;
    color: rgb(255, 230, 0);
  }
`;
