import React from 'react';
import {
  StyledCategory,
  StyledCategoryIcon,
  StyledCategoryHeading,
  StyledCategories,
  StyledCategoryList,
  StyledCategoryListItem,
} from './styled';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

const CourseCategories = ({ categories }) => (
  <Row>
    {categories.map(category => (
      <Col xs={12} md={4} key={category.name} className="mb-4">
        <StyledCategory key={category.name}>
          <StyledCategoryIcon src={category.image} alt="Frontend Categories" />
          <StyledCategoryHeading>{category.name}</StyledCategoryHeading>
          <StyledCategories>
            <StyledCategoryList marginRight>
              {category.values.map(value => (
                <StyledCategoryListItem key={value.name}>
                  <Link to={value.href}>{value.name}</Link>
                </StyledCategoryListItem>
              ))}
            </StyledCategoryList>
          </StyledCategories>
        </StyledCategory>
      </Col>
    ))}
  </Row>
);

export default CourseCategories;
