import { stripUnit } from 'polished';

/* eslint-disable import/prefer-default-export */
export const multiply = (base = '1rem', multiplier = 1) => {
  switch (multiplier) {
    case 0:
      return 0;

    case 1:
      return base;

    default: {
      const [value, unit] = stripUnit(base, true);
      return `${value * multiplier}${unit}`;
    }
  }
};
