import React, { useState, Fragment } from 'react';
import PasswordResetForm from '../../components/Forms/PasswordResetForm';
import Alert from '../../components/UI/Alert';
import Layout from '../../components/Layout';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { loginMetaTags } from '../../helpers/constants';
import { createStructuredSelector } from 'reselect';
import { selectCurrentUser } from '../../redux/user/user.selectors';
import {
  StyledLogin,
  StyledLeftContent,
  StyledLoginTitle,
  StyledLoginLeft,
  StyledLogoWrapper,
  StyledLogo,
  StyledLoginRight,
} from '../Login/styled';
import learnstabilityLogo from '../../public/static/image/ls7.svg';

const RequestPasswordReset = ({ currentUser }) => {
  const [emailSent, setEmailSent] = useState();
  return (
    <Fragment>
      {currentUser && <Redirect to="/course-search" />}
      <Layout metaTags={loginMetaTags}>
        <StyledLogin>
          <StyledLoginLeft>
            <StyledLeftContent>
              <StyledLogoWrapper>
                <StyledLogo src={learnstabilityLogo} alt="Learnstability Logo" />
              </StyledLogoWrapper>
              {emailSent && <Alert color="success">Your password reset has been submitted.</Alert>}
              <StyledLoginTitle>Request Password Reset!</StyledLoginTitle>
              <PasswordResetForm setEmailSent={setEmailSent} />
            </StyledLeftContent>
          </StyledLoginLeft>
          <StyledLoginRight />
        </StyledLogin>
      </Layout>
    </Fragment>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

export default connect(mapStateToProps)(RequestPasswordReset);
