import { firestore } from '../../firebase/firebase.utils';

export const calculateReviewData = reviewData => {
  if (Array.isArray(reviewData) && reviewData.length) {
    const sum = reviewData.reduce((previous, current) => (current += previous));
    return (sum / reviewData.length).toFixed(1);
  }
};

export const getCourseReviews = currentCourse => {
  try {
    const collectionRef = firestore.collection('reviews');
    collectionRef
      .where('courseId', '==', currentCourse.id)
      .limit(10)
      .get()
      .then(snapshot => {
        const result = snapshot.docs.map(doc => doc.data());
        return result;
      });
  } catch (error) {}
};
