import styled from 'styled-components';

export const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 1rem;
  position: absolute;
  top: 3.7rem;
  transition: transform 0.3s ease-in-out;
  transform: ${({ open }) => (open ? 'translateX(-96%)' : 'translateX(100%)')};
  background: white;
  z-index: 2;
  width: 100%;

  a {
    font-size: 1rem;
    padding: 0rem 0 1rem 0;
    text-decoration: none;
    transition: color 0.3s linear;
    color: black;
    @media (max-width: ${({ theme }) => theme.mobile}) {
      font-size: 1.5rem;
      text-align: center;
    }

    &:hover {
      color: ${({ theme }) => theme.primaryHover};
    }
  }
`;

export const StyledUserMenu = styled.div`
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
`;
