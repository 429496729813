import React from 'react';
import { StyledBenefitBox, StyledBenefitContent, StyledBenefitTitle, StyledBenefitText } from './styled';
import Icon from '../UI/Icon';

const Benefits = () => (
  <div className="row no-gutters">
    <div className="col-lg-4 col-md-6 col-sm-6 col-12">
      <StyledBenefitBox>
        <Icon name="ti-html5" color="#63b0e3" backgroundColor="#c6e6fd" />
        <StyledBenefitContent>
          <StyledBenefitTitle>Learn Top Languages</StyledBenefitTitle>
          <StyledBenefitText>
            From <strong>HTML</strong>, <strong>CSS</strong> and <strong>JavaScript</strong> to
            <strong> React</strong>, <strong>Python</strong> and <strong>Vue</strong> we provide you with skills to do
            the job.
          </StyledBenefitText>
        </StyledBenefitContent>
      </StyledBenefitBox>
    </div>
    <div className="col-lg-4 col-md-6 col-sm-6 col-12">
      <StyledBenefitBox>
        <Icon name="ti-desktop" color="#b17cc6" backgroundColor="#efdff7" />
        <StyledBenefitContent>
          <StyledBenefitTitle>Code Challeneges</StyledBenefitTitle>
          <StyledBenefitText>
            Take part in expert created code challeneges and push your code to Github to build a portfolio.
          </StyledBenefitText>
        </StyledBenefitContent>
      </StyledBenefitBox>
    </div>
    <div className="col-lg-4 col-md-6 col-sm-6 col-12">
      <StyledBenefitBox>
        <Icon name="ti-announcement" color="#bd2130" backgroundColor="#ffe0e3" />
        <StyledBenefitContent>
          <StyledBenefitTitle>Daily On The Job Duties</StyledBenefitTitle>
          <StyledBenefitText>
            Learn the things an online course cant teach you, such as agile, meetings and responsibilities.
          </StyledBenefitText>
        </StyledBenefitContent>
      </StyledBenefitBox>
    </div>
    <div className="col-lg-4 col-md-6 col-sm-6 col-12">
      <StyledBenefitBox>
        <Icon name="ti-money" color="#45d7ba" backgroundColor="#ddedea" />
        <StyledBenefitContent>
          <StyledBenefitTitle>One Track One Price</StyledBenefitTitle>
          <StyledBenefitText>
            No monthly subscription or lock-ins, purchase a track for a price and become a member for life.
          </StyledBenefitText>
        </StyledBenefitContent>
      </StyledBenefitBox>
    </div>
    <div className="col-lg-4 col-md-6 col-sm-6 col-12">
      <StyledBenefitBox>
        <Icon name="ti-microphone" color="#6582ed" backgroundColor="#e2e8ff" />
        <StyledBenefitContent>
          <StyledBenefitTitle>Join A Community</StyledBenefitTitle>
          <StyledBenefitText>
            As a member you can also join our private Slack channels to ask for help or just disucss topics.
          </StyledBenefitText>
        </StyledBenefitContent>
      </StyledBenefitBox>
    </div>
    <div className="col-lg-4 col-md-6 col-sm-6 col-12">
      <StyledBenefitBox>
        <Icon name="ti-stats-up" color="#ed7265" backgroundColor="#efe3e3" />
        <StyledBenefitContent>
          <StyledBenefitTitle>Track Your Progress</StyledBenefitTitle>
          <StyledBenefitText>
            Our platform alows you to easily track your progress online with an aim to motivate you to learn more.
          </StyledBenefitText>
        </StyledBenefitContent>
      </StyledBenefitBox>
    </div>
  </div>
);

export default Benefits;
