import { takeLatest, call, put, all } from 'redux-saga/effects';
import { firestore } from '../../firebase/firebase.utils';
import { fetchReviewsSuccess, fetchReviewsFailure } from './review.actions';
import ReviewActionTypes from './review.types';

export function* fetchReviewsAsync() {
  try {
    const collectionRef = firestore.collection('reviews');
    const snapshot = yield collectionRef.get();
    const reviewData = yield snapshot.docs.map(doc => doc.data());
    yield put(fetchReviewsSuccess(reviewData));
  } catch (error) {
    yield put(fetchReviewsFailure(error.message));
  }
}

export function* fetchReviewsStart() {
  yield takeLatest(ReviewActionTypes.FETCH_REVIEWS_START, fetchReviewsAsync);
}

export function* reviewSagas() {
  yield all([call(fetchReviewsStart)]);
}
