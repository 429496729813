import React from 'react';
import styled from 'styled-components';
import checkIcon from '../../../public/static/image/icons/check.svg';

export const StyledCourseDetailsCard = styled(({ ...props }) => <div {...props} />)`
  text-decoration: none;
  border-radius: 5px;
  transition: all 0.7s ease;
  margin-bottom: 1.5rem;
  transition: transform 0.2s ease-in-out;
  margin-bottom: 1rem;
  position: relative;
  border-radius: 5px;
  border: solid 1px #d8d5d5;
  background-color: #fff;
  padding: 1rem;

  @media (min-width: 480px) {
    margin-bottom: 2rem;
  }

  a {
    text-decoration: none;
  }
`;

export const StyledFeatureList = styled.div``;

export const StyledFeature = styled.div`
  display: flex;
  align-items: center;

  ul {
    padding: 0;
    margin: 0;
  }

  li {
    background-image: url(${checkIcon});
    background-position: left center;
    background-repeat: no-repeat;
    padding-left: 35px;
    list-style: none;
    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }

  span {
    font-size: 0.9rem;
    color: #3d414a;
  }
`;
