import styled from 'styled-components';
import StarRatingComponent from 'react-star-rating-component';

export const StyledBlogCard = styled.div`
  text-decoration: none;
  border-radius: 12px;
  box-shadow: 0 60px 120px rgba(150, 155, 187, 0.2);
  transition: all 0.7s ease;
  margin-bottom: 1.5rem;
  transition: transform 0.2s ease-in-out;
  margin-bottom: 1rem;
  cursor: pointer;

  @media (min-width: 480px) {
    margin-bottom: 2rem;
  }

  &:hover {
    transform: translate(0, -2px);
  }
  a {
    text-decoration: none;
  }
`;

export const StyledBlogTitle = styled.h3`
  margin: 0;
  line-height: 27px;
  padding: 1.5rem 1.5rem 0;
  font-family: 'Graphik', sans-serif;
  font-size: 1.3rem;
  font-weight: 700;
  color: #32325d;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;

export const StyledBlogMeta = styled.p`
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.5rem 1.5rem;
  color: #7f92a4;
  font-weight: 500;
  margin-bottom: 1rem;
`;
export const StyledBlogMetaItem = styled.small`
  font-size: 1rem;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
`;

export const StyledBlogImage = styled.img`
  width: 100%;
  border-radius: 12px 12px 0 0;
  max-height: 200px;
`;

export const StyledBlogTags = styled.div`
  padding: 0rem 1.5rem 1rem;
  display: flex;
  flex-wrap: wrap;
`;

export const StyledBlogTag = styled.span`
  background-color: #ececec;
  border-color: #ececec;
  padding: 2px 8px 3px;
  color: grey;
  font-size: 0.8rem;
  line-height: 24px;
  border-radius: 12px;
  transition: all 0.2s ease-in;
  cursor: initial;
  margin-right: 5px;
  margin-bottom: 5px;

  &:hover {
    background: #0166bb;
    color: white;
  }
`;

export const StyledPublishedDate = styled.span`
  padding: 0rem 1.5rem 1.5rem;
  display: flex;
  flex-wrap: wrap;
  font-size: 0.9rem;
  line-height: 24px;
  overflow: hidden;
  color: #7f92a4;
`;

export const StyledStarRating = styled(StarRatingComponent)`
  padding: 0rem 1.5rem 0rem;
`;
