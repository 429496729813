import React from 'react';
import { StyledCourseDetailsCard, StyledFeatureList, StyledFeature } from './styled';
import ReactHtmlParser from 'react-html-parser';

const CourseDetailsCard = ({ keyTopics }) => (
  <StyledCourseDetailsCard bordershadow="true">
    <StyledFeatureList>
      <StyledFeature>
        <span>{ReactHtmlParser(keyTopics)}</span>
      </StyledFeature>
    </StyledFeatureList>
  </StyledCourseDetailsCard>
);

export default CourseDetailsCard;

// <StyledFeature>
//   <Icon name="ti-video-clapper" color="#3d414a" />
//   <span>{length}</span>
// </StyledFeature>
// <StyledFeature>
//   <Icon name="ti-folder" color="#3d414a" />
//   <span>{resources}</span>
// </StyledFeature>
// <StyledFeature>
//   <Icon name="ti-mobile" color="#3d414a" />
//   <span>{access}</span>
// </StyledFeature>
// <StyledFeature>
//   <Icon name="ti-check" color="#3d414a" />
//   <span>{verified}</span>
// </StyledFeature>
// <StyledFeature>
//   <Icon name="ti-id-badge" color="#3d414a" />
//   <span>{certificate}</span>
// </StyledFeature>
