import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Button from '../UI/Button';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';
import NavAccountDropdown from './NavAccountDropdown';
import LogoImage from '../../public/static/image/ls6.svg';
import LogoImageWhite from '../../public/static/image/ls6-white.svg';
import {
  StyledNavbarWrapper,
  StyledNavButton,
  StyledLogo,
  StyledNavMenu,
  StyledNavMenuList,
  StyledNavMenuItem,
} from './styled';
import { Row } from 'reactstrap';
import { selectCurrentUser } from '../../redux/user/user.selectors';
import { signOutStart } from '../../redux/user/user.actions';
// import { ReactComponent as SearchIcon } from '../../public/static/image/icons/search.svg';
import { useMediaQuery } from 'react-responsive';
import Burger from '../UI/Burger';
import BurgerMenu from '../UI/BurgerMenu';

const Navbar = ({ logoStyle, currentUser, signOutStart, background }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [open, setOpen] = useState(false);

  return (
    <StyledNavbarWrapper background={background}>
      <Container>
        <Row className="justify-content-between mr-0 ml-0 align-items-center">
          {background ? (
            <StyledLogo
              href="/course-search"
              logoSrc={LogoImageWhite}
              title="Logo"
              logoStyle={logoStyle}
              className="main-logo"
            />
          ) : (
            <StyledLogo
              href="/course-search"
              logoSrc={LogoImage}
              title="Logo"
              logoStyle={logoStyle}
              className="main-logo"
            />
          )}

          {isMobile && (
            <div>
              <Burger open={open} setOpen={setOpen} background={background} />
              {open && <BurgerMenu open={open} setOpen={setOpen} currentUser={currentUser} />}
            </div>
          )}

          {!isMobile && (
            <StyledNavMenu background={background}>
              <StyledNavMenuList>
                <StyledNavMenuItem>
                  <Link to={'/course-search'}>Explore Courses</Link>
                </StyledNavMenuItem>
                <StyledNavMenuItem>
                  <Link to={'/blog'}>Blog</Link>
                </StyledNavMenuItem>
                {currentUser && (
                  <StyledNavMenuItem>
                    <Link to={'/wish-list'}>Saved</Link>
                  </StyledNavMenuItem>
                )}

                <StyledNavMenuItem>
                  <Link to={'/help'}>Help</Link>
                </StyledNavMenuItem>
              </StyledNavMenuList>

              {currentUser && (
                <NavAccountDropdown
                  handleSignOut={signOutStart}
                  userName={currentUser.displayName}
                  background={background}
                />
              )}

              {!currentUser && (
                <StyledNavButton>
                  <Button size="lg">
                    <Link to="/login">Log in</Link>
                  </Button>
                </StyledNavButton>
              )}
            </StyledNavMenu>
          )}
        </Row>
      </Container>
    </StyledNavbarWrapper>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

const mapDispatchToProps = dispatch => ({
  signOutStart: () => dispatch(signOutStart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);

// <StyledNavMenuItem>
//   <Link to={'/platforms'}>Platforms</Link>
// </StyledNavMenuItem>;
