import React from 'react';
import styled from 'styled-components';
import { Form } from 'formik';
import Button from '../../UI/Button';
import { color } from '../../../helpers/style';

export const StyledSearchForm = styled(({ shadow, align, ...props }) => <Form {...props} />)`
  display: flex;
  box-shadow: ${props => (props.shadow ? '0 0 5px 3px rgba(0, 0, 0, 0.05)' : 'none')};
  width: 100%;
  border-radius: 5px;
  text-align: center;
  margin: ${({ align }) => (align === 'center' ? '0 auto' : 'unset')};
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 600;
  &:hover {
    box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
    border-color: rgba(223, 225, 229, 0);
  }

  @media (min-width: 480px) {
    width: 50%;
  }

  fieldset {
    width: 100%;
  }
  label {
    display: block;
    text-align: left;
    margin: 0;
  }
  input {
    flex: 1;
    padding: 0.8rem;
    font-size: 1rem;
    border: 0px;
    border-radius: 5px 0px 0px 5px;
    overflow: hidden;
    border-style: solid;
    padding-left: 1rem;

    @media (min-width: 480px) {
      padding: 1rem;
      margin-bottom: 0;
      padding-left: 1rem;
    }

    &:focus {
      outline: 0;
    }
    &::placeholder {
      color: #b1b1b1;
      font-size: 1rem;
    }
  }
  button,
  input[type='submit'] {
    width: auto;
    background: ${color('blueSea')};
    border-radius: 0px 5px 5px 0px;
    color: white;
    border: 0;
    font-size: 1rem;
    font-weight: 600;
    padding: 0.5rem 1.2rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background: ${color('matisse')};
    }

    &[disabled] {
      opacity: 0.5;
    }
    &[aria-busy='true']::before {
      background-size: 50% auto;
    }
  }
  .form-title {
    padding-bottom: 1.5rem;
    font-size: 1.4rem;
    color: #1d2c4c;
    font-weight: bold;
  }
  .delete {
    background: #fb4242;
    border-radius: 5px;
    color: white;
    border: 0;
    font-size: 1rem;
    font-weight: 600;
    padding: 0.5rem 1.2rem;
    text-transform: uppercase;
    cursor: pointer;
  }
`;

export const StyledSearchInput = styled.div`
  display: flex;
`;

export const StyledButton = styled(Button)`
  outline: 0;
  width: auto;
  img {
    width: 80%;
  }
  button {
    width: 20%;
    background: ${color('loch')};
    &:hover {
      background: ${color('keppel')};
    }
  }
`;
