import styled from 'styled-components';
// import NavbarWrapper from '../UI/Navbar';
import Logo from '../UI/Logo';

export const StyledNavbarWrapper = styled.div`
  position: relative;
  background: ${({ background }) => (background ? 'transparent' : 'white')};
  display: flex;
  align-items: center;
  padding: 1.2rem 0;
  @media (min-width: 480px) {
    padding: 1rem 0;
  }
`;

export const StyledNavButton = styled.div`
  button {
    min-width: 80px;
    font-size: 16px;
  }
  @media (min-width: 990px) {
    display: block;
  }
  display: none;
`;

export const StyledLogo = styled(Logo)`
  img {
    width: 85%;
    @media (min-width: 480px) {
      width: 100%;
    }
  }
`;

export const StyledNavMenuList = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;
  padding-right: 2rem;
`;

export const StyledNavMenuItem = styled.li`
  list-style: none;
  padding: 0;
  margin: 0;
  line-height: 0;
  &:not(:last-child) {
    padding-right: 1.8rem;
  }
  a {
    font-size: 16px;
    color: #42526e;
    line-height: 20px;
    text-decoration: none;
    &:hover {
      color: #173a84;
      border-bottom: solid 2px white;
      padding-bottom: 0.5rem;
    }
  }
`;

export const StyledNavMenu = styled.div`
  display: flex;
  align-items: center;
  line-height: 24px;

  ${StyledNavMenuItem} {
    a {
      color: ${({ background }) => (background ? 'white' : '#42526e;')};
    }
  }
`;

export const StyledNavIcon = styled.button`
  background: transparent;
  border: 0;
  padding: 0;
  svg {
    width: 30px;
  }
`;
