import frontEndImage from '../public/static/image/icons/frontend.svg';
import backEndImage from '../public/static/image/icons/backend.svg';
import devOpsImage from '../public/static/image/icons/dev.svg';
import jsImage from '../public/static/image/icons/javascript.svg';
import swiftIcon from '../public/static/image/icons/swift.svg';
import python from '../public/static/image/icons/python.svg';

export const categories = [
  {
    name: 'Front End Categories',
    image: `${jsImage}`,
    values: [
      {
        name: 'JavaScript',
        href: '/course-search/search?q=javascript',
      },
      {
        name: 'React',
        href: '/course-search/search?q=react',
      },
      {
        name: 'Vue JS',
        href: '/course-search/search?q=vue',
      },
      {
        name: 'HTML & HTML5',
        href: '/course-search/search?q=html',
      },
      {
        name: 'CSS',
        href: '/course-search/search?q=css',
      },
      {
        name: 'Sass',
        href: '/course-search/search?q=sass',
      },
      {
        name: 'Gatsby',
        href: '/course-search/search?q=gatsby',
      },
      {
        name: 'Next JS',
        href: '/course-search/search?q=next',
      },
      {
        name: 'Wordpress',
        href: '/course-search/search?q=wordpress',
      },
      {
        name: 'Redux',
        href: '/course-search/search?q=redux',
      },
      {
        name: 'GraphQL',
        href: '/course-search/search?q=graphql',
      },
      {
        name: 'VSCode',
        href: '/course-search/search?q=code',
      },
    ],
  },

  {
    name: 'Back End Categories',
    image: `${python}`,
    values: [
      {
        name: 'Python',
        href: '/course-search/search?q=python',
      },
      {
        name: 'Node.JS',
        href: '/course-search/search?q=node',
      },
      {
        name: 'Django',
        href: '/course-search/search?q=django',
      },
      {
        name: 'Java',
        href: '/course-search/search?q=java',
      },
      {
        name: 'Laravel',
        href: '/course-search/search?q=laravel',
      },
      {
        name: 'Spring MVC',
        href: '/course-search/search?q=spring',
      },
      {
        name: 'Flask',
        href: '/course-search/search?q=flask',
      },
      {
        name: 'Ruby on Rails',
        href: '/course-search/search?q=ruby',
      },
      {
        name: 'ASP.NET',
        href: '/course-search/search?q=asp',
      },
      {
        name: 'Express',
        href: '/course-search/search?q=express',
      },
      {
        name: 'Performance Optimization',
        href: '/course-search/search?q=express',
      },
    ],
  },

  {
    name: 'Mobile Categories',
    image: `${swiftIcon}`,
    values: [
      {
        name: 'Andriod Development',
        href: '/course-search/search?q=andriod',
      },
      {
        name: 'IOS Development',
        href: '/course-search/search?q=ios',
      },
      {
        name: 'Google Flutter',
        href: '/course-search/search?q=big+flutter',
      },
      {
        name: 'Swift',
        href: '/course-search/search?q=swift',
      },
      {
        name: 'React Native',
        href: '/course-search/search?q=react+native',
      },
      {
        name: 'Dart',
        href: '/course-search/search?q=dart',
      },
      {
        name: 'Kotlin',
        href: '/course-search/search?q=big+kotlin',
      },
      {
        name: 'Mobile Development',
        href: '/course-search/search?q=mobile',
      },
    ],
  },
];

export const topCourses = [
  {
    name: 'Front End Development',
    image: `${frontEndImage}`,
    values: [
      {
        name: 'Wes Bos Advanced React',
        href: '/course/fullstack-advanced-react-and-graphql',
      },
      {
        name: 'Modern JS From The Beginning',
        href: '/course-search/search?q=modern-javascript',
      },
      {
        name: 'Vue JS 2 The Complete Guide',
        href: '/course-search/search?q=vue-the-complete',
      },
      {
        name: 'The Complete Web Developer Course',
        href: '/course-search/search?q=complete-web-developer',
      },
      {
        name: 'Advanced CSS & SCSS',
        href: '/course-search/search?q=advanced-css',
      },
    ],
  },

  {
    name: 'Back End Development',
    image: `${backEndImage}`,
    values: [
      {
        name: 'Complete Python Bootcamp',
        href: '/course-search/search?q=python',
      },
      {
        name: 'The Complete SQL Bootcamp',
        href: '/course-search/search?q=sql',
      },
      {
        name: 'Go The Complete Developers Guide',
        href: '/course-search/search?q=go',
      },
      {
        name: 'Node With React',
        href: '/course-search/node',
      },
      {
        name: 'Selinium Webdriver With Java',
        href: '/course-search/search?q=java',
      },
    ],
  },

  {
    name: 'App Development',
    image: `${devOpsImage}`,
    values: [
      {
        name: 'Andriod Development',
        href: '/course-search/search?q=andriod',
      },
      {
        name: 'IOS Development',
        href: '/course-search/search?q=ios',
      },
      {
        name: 'Google Flutter',
        href: '/course-search/search?q=big+flutter',
      },
      {
        name: 'Swift',
        href: '/course-search/search?q=swift',
      },
      {
        name: 'React Native',
        href: '/course-search/search?q=react+native',
      },
      {
        name: 'Dart',
        href: '/course-search/search?q=dart',
      },
      {
        name: 'Kotlin',
        href: '/course-search/search?q=big+kotlin',
      },
      {
        name: 'Mobile Development',
        href: '/course-search/search?q=mobile',
      },
    ],
  },
];
