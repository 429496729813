import styled from 'styled-components';
import hero1 from '../../public/static/image/heros/g8.jpg';

export const HomeDarkBackground = styled.section`
  background: #f7f7f7;
  padding: 4rem 0;

  @media (min-width: 480px) {
    padding: 6rem 0;
  }

  @media (min-width: 780px) {
    padding: 14rem 0;
  }
`;

export const HomeLightBackground = styled.section`
  background: #fff;
  padding: 4rem 0;

  @media (min-width: 480px) {
    padding: 6rem 0;
  }

  @media (min-width: 780px) {
    padding: 14rem 0;
  }
`;

export const StyledHomeHero = styled.div`
  background: url(${hero1}) top center no-repeat;
  background-size: cover;
  position: relative;
  background-position-y: 55%;
  position: relative;
  background-position-x: 55%;
  @media (min-width: 480px) {
    background-position-x: auto;
  }
`;

export const StyledHomeHeroContent = styled.div`
  padding: 7rem 0rem 7rem 0;
  @media (min-width: 480px) {
    padding: 14rem 0 24rem 0;
  }
`;

export const StyledHomeHeroContentText = styled.div`
  padding-bottom: 2rem;
  h2 {
    font-weight: 600;
    color: white;
    font-size: 1.8rem;
    text-shadow: 0 0 12px #212224;

    @media (min-width: 480px) {
      padding-bottom: 1rem;
      font-size: 2.9rem;
      width: 50%;
    }
  }
  p {
    color: white;

    @media (min-width: 480px) {
      width: 50%;
      font-size: 1.2rem;
    }
  }
`;

export const StyledHomeHeroWrap = styled.section`
  line-height: 30px;
`;

export const StyledHomeHeroShape = styled.img`
  position: absolute;
  left: 0;
  bottom: 0;
  height: auto;
  display: block;
  margin: -2px 0px;
  width: 100%;
`;
